import React from "react";

const Profile = ({ username, avatarUrl, post }) => {
  return (
    <div className="flex items-center justify-center py-3">
      <img
        src={avatarUrl}
        alt={username}
        className="sm:w-24 sm:h-24 w-20 h-20 object-cover rounded-full bg-white"
      />
      <div>
        <div className="ml-4 sm:text-md text-sm font-bold capitalize">
          {username}
        </div>
        <div className="ml-4 text-sm">{post} post</div>
      </div>
    </div>
  );
};

export default Profile;
