import React, { useState } from "react";
import { ArrowUp } from "../../assets/Icons/MenuIcon";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisibility);

  return (
    <div
      className={`fixed right-4 bottom-20 sm:bottom-4 z-50 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <button
        className="text-white bg-gray-700 rounded-full p-2 focus:outline-none hover:bg-gray-600"
        onClick={scrollToTop}
      >
        <ArrowUp width="20" height="20" fill="#ee5873" />
      </button>
    </div>
  );
};

export default ScrollToTop;
