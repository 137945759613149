import { useContext, useState } from "react";

import { SavedIcon } from "../../assets/Icons/MenuIcon";
import HeaderImage from "../../components/UI/HeaderImage";
import { saveOrUnsaveRecipe } from "../../api/request";
import { AuthContext } from "../../context/authContext";
import { SaveContext } from "../../context/saveContext";
import { EditIcon } from "../../assets/Icons/PageIcons";
import { NavLink } from "react-router-dom";
import Notify from "../../components/UI/Notify";
import { useTranslation } from "react-i18next";

const Header = ({ recipeId, image, isSaved, setIsSaved, admin }) => {
  const { t } = useTranslation();
  const { token, isLoggedIn } = useContext(AuthContext);
  const { addFavorite, removeFavorite } = useContext(SaveContext);
  const [showNotify, setShowNotify] = useState(false);

  const handleSaveRecipe = async () => {
    if (!token || !isLoggedIn) {
      setShowNotify(true);
      return;
    }

    try {
      if (isSaved) {
        removeFavorite(recipeId);
        setIsSaved(false);
      } else {
        addFavorite(recipeId);
        setIsSaved(true);
      }
      await saveOrUnsaveRecipe(token, recipeId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setShowNotify(false);
  };

  return (
    <>
      {showNotify && (
        <Notify
          message={t("Plase Login Or Register First")}
          type="warning"
          onClose={handleClose}
        />
      )}

      <HeaderImage img={`${process.env.REACT_APP_ASSET_URL}/${image}`}>
        <div className="absolute bottom-5 right-5 flex-col z-10 flex items-center gap-4">
          {admin && (
            <NavLink
              to={`/edit-recipe/${recipeId}`}
              className="flex items-center gap-1"
            >
              <p className=" text-sm sm:text-lg pr-1 text-white font-bold sm:font-normal">
                {t("edit")}
              </p>
              <button className="bg-white sm:w-11 sm:h-11 w-10 h-10 ease-out duration-500 rounded-full flex items-center justify-center">
                <EditIcon
                  className=" inline-block w-3 h-3 sm:h-4 sm:w-4 "
                  fill="black"
                />
              </button>
            </NavLink>
          )}
          <div className="flex items-center gap-1">
            <p className=" text-sm sm:text-lg pr-1 text-white font-bold sm:font-normal">
              {t("save")}
            </p>
            <button
              onClick={handleSaveRecipe}
              className={`${
                isSaved ? "bg-secondary" : "bg-white"
              } sm:w-11 sm:h-11 w-10 h-10 ease-out duration-500 rounded-full flex items-center justify-center`}
            >
              <SavedIcon
                stroke={`${isSaved ? "white" : "black"}`}
                width="22"
                height="22"
                thick="16"
              />
            </button>
          </div>
        </div>
      </HeaderImage>
    </>
  );
};

export default Header;
