import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TagsInput.module.css";

const TagsInput = ({ maxTags = 5, className, onTagsAdd, tags }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "," || event.key === " ") {
      event.preventDefault();
      if (inputValue && tags.length < maxTags) {
        onTagsAdd([...tags, inputValue]);
        setInputValue("");
      }
    }
  };

  const handleRemoveTag = (index) => {
    onTagsAdd(tags.filter((tag, i) => i !== index));
  };

  return (
    <div>
      <div className={styles.tags_container}>
        {tags.map((tag, index) => (
          <span className={styles.tag} key={index}>
            {tag}
            <span
              className={styles.remove_icon}
              onClick={() => handleRemoveTag(index)}
            >
              ×
            </span>
          </span>
        ))}
        <input
          className={`${className} !inline-block`}
          type="text"
          value={inputValue}
          placeholder={t("eg: Doro Ethiopian")}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {tags.length === maxTags && (
        <p className=" text-sm text-red-500">
          You have reached the maximum number of tags.
        </p>
      )}
    </div>
  );
};

export default TagsInput;
