import React from "react";

import { Navbar, SideBar, BottomNav } from "../index";

const Layout = (props) => {
  return (
    <>
      <div className=" relative z-0 bg-gray-50">
        <Navbar />
        <SideBar />
        {props.children}
        <BottomNav />
      </div>
    </>
  );
};

export default Layout;
