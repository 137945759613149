import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { BurgerMenu } from "../../assets/Icons/MenuIcon";
import { LoginSignup, UserButton } from "../index";
import { SideMenuContext } from "../../context/sideMenuContext";
import { AuthContext } from "../../context/authContext";
import SearchBar from "./SearchBar";
import LanguageSelector from "../UI/LanguageSelector";

const Navbar = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { toggleSideMenu } = useContext(SideMenuContext);
  return (
    <header className="sticky w-full top-0 z-20">
      <div className=" shadow-sm px-3 sm:px-6 flex items-center justify-between py-3 bg-white text-white">
        <div className="flex items-center gap-2 sm:gap-4 ">
          <button
            className="hidden md:block text-secondary hover:text-btnHover"
            type="button"
            onClick={toggleSideMenu}
          >
            <BurgerMenu width="30" height="30" thick="22" />
          </button>

          <NavLink
            to="/"
            className="capitalize text-lg md:text-2xl  text-secondary font-logoFont cursor-default "
          >
            Gebeta Recipes
          </NavLink>
        </div>

        <SearchBar />
        <div className="flex items-center">
          <LanguageSelector />
          {isLoggedIn ? <UserButton /> : <LoginSignup />}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
