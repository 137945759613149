import React from "react";
import { Footer, HeaderBanner } from "../components";
import { Seo } from "../util/Seo";
import ContactSocial from "./ContactUs/ContactSocial";

const PrivacyPolicy = () => {
  return (
    <div className="main-section">
      <Seo
        title="Privacy Policy | Gebeta Recipes"
        description="Read our privacy policy to learn about how we collect and use your personal information."
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url="https://gebetarecipes.com/privacy-policy"
      />
      <div className="sm:px-6">
        <HeaderBanner>
          <ContactSocial />
        </HeaderBanner>
        <div className="my-8 px-6">
          <div>
            <h1 className="text-center text-xl font-bold">Privacy Policy</h1>
            <p>
              At<span className="font-bold"> Gebeta Recipe </span>, we are
              committed to protecting your privacy. This Privacy Policy outlines
              the types of personal information we collect, how it is used, and
              the steps we take to ensure your personal information is
              protected.
            </p>
            <h2 className="font-bold">Information We Collect</h2>
            <p>
              When you visit our website, we automatically collect certain
              information about your device, including information about your
              web browser, IP address, time zone, and some of the cookies that
              are installed on your device. Additionally, as you browse our
              website, we collect information about the individual web pages or
              products that you view, what websites or search terms referred you
              to our website, and information about how you interact with our
              website. We refer to this automatically-collected information as
              “Device Information.”
            </p>
            <div>
              We collect Device Information using the following technologies:
              <ul>
                <li>
                  “Cookies” are data files that are placed on your device or
                  computer and often include an anonymous unique identifier. For
                  more information about cookies, and how to disable cookies,
                  visit http://www.allaboutcookies.org.
                </li>
                <li>
                  “Log files” track actions occurring on our website, and
                  collect data including your IP address, browser type, Internet
                  service provider, referring/exit pages, and date/time stamps.
                </li>
                <li>
                  “Web beacons,” “tags,” and “pixels” are electronic files used
                  to record information about how you browse our website.
                </li>
              </ul>
            </div>
            <p>
              Additionally, when you sign up for an account on our website or
              subscribe to our newsletter, we collect certain personal
              information from you, including your name and email address. We
              refer to this information as “Registration Information.”
            </p>
            <p>
              When you use our website to search for recipes, we may collect
              additional information about your preferences, such as the type of
              cuisine or dietary restrictions you are interested in. We refer to
              this information as “Search Information.”
            </p>
            <h2 className="font-bold">How We Use Your Information</h2>
            <p>
              We use the information we collect to provide you with a better
              experience on our website, to understand how our website is used,
              and to communicate with you about our products and services.
            </p>
            <p>
              Device Information is used to help us screen for potential risk
              and fraud (in particular, your IP address), and more generally to
              improve and optimize our website (for example, by generating
              analytics about how our customers browse and interact with our
              website, and to assess the success of our marketing and
              advertising campaigns).
            </p>
            <p>
              Registration Information is used to create and maintain your
              account on our website, and to communicate with you about our
              products and services.
            </p>
            <p>
              Search Information is used to help us understand what types of
              recipes our customers are interested in, and to make
              recommendations for other recipes you may enjoy.
            </p>
            <h2 className="font-bold">Sharing Your Personal Information</h2>
            <p>
              We are committed to protecting your personal information and we do
              not sell, trade, or rent your personal information to third
              parties for their marketing purposes without your consent.
              However, we may share your personal information with third-party
              service providers who perform services on our behalf, such as
              payment processing, data analysis, and email communication. These
              third-party service providers are contractually obligated to use
              your personal information only for the purpose of providing
              services to us and are prohibited from sharing or using your
              personal information for any other purpose.
            </p>
            <p>
              We may also disclose your personal information if required by law,
              such as in response to a court order or subpoena, or to comply
              with applicable laws and regulations.
            </p>
            <p>
              In the event that our business is sold or merged, your personal
              information may be transferred to the new owner so that they can
              continue to provide you with the same products and services that
              you have been receiving from us.
            </p>
            <h2 className="font-bold">Security of Your Personal Information</h2>
            <p>
              We take reasonable measures to protect your personal information
              from unauthorized access, use, or disclosure. However, no method
              of transmission over the Internet or electronic storage is 100%
              secure, so we cannot guarantee the absolute security of your
              personal information. If you have any concerns about the security
              of your personal information, please contact us using the
              information provided below.
            </p>
            <h2>Changes to this Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time in order to
              reflect changes to our practices or for other operational, legal,
              or regulatory reasons. If we make any material changes to this
              Privacy Policy, we will notify you by posting the updated Privacy
              Policy on our website and updating the "Effective Date" at the top
              of this Privacy Policy. Your continued use of our website after
              the effective date of any changes indicates your acceptance of the
              updated Privacy Policy.
            </p>
            <h2 className="font-bold">Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our practices, please contact us at gebetarecipes@gmail.com.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
