import React, { useContext, useEffect, useState } from "react";

import Header from "./Header";
import Post from "./Post";
import AddRating from "./AddRating";
import UserRating from "./UserRating";
import { useParams } from "react-router-dom";
import { getRecipeById, getRecipeReviews } from "../../api/request";
import { SaveContext } from "../../context/saveContext";
import { AuthContext } from "../../context/authContext";
import MainLoader from "../../components/UI/MainLoader";
import Footer from "../../components/Footer";
import { Seo } from "../../util/Seo";

function Recipe() {
  const { recipeId } = useParams();
  const { ids } = useContext(SaveContext);
  const { userId } = useContext(AuthContext);
  const [isSaved, setIsSaved] = useState(false);

  const [recipeLoading, setRecipeLoading] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [recipe, setRecipe] = useState({});
  const [reviews, setReviews] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setIsSaved(ids.includes(recipeId));
  }, [recipeId, ids]);

  useEffect(() => {
    const fetchRecipe = async () => {
      setRecipeLoading(true);
      try {
        const res = await getRecipeById(recipeId);

        if (res.status === 200) {
          setRecipe(res.data);
        }
        setRecipeLoading(false);
      } catch (error) {
        setRecipeLoading(false);
      }
    };
    fetchRecipe();
  }, [recipeId]);

  //

  useEffect(() => {
    const fetchRecipeReviews = async () => {
      setReviewLoading(true);
      const res = await getRecipeReviews(recipeId, 5, offset);

      if (res.status === 200) {
        setReviews((prev) => [...prev, ...res.data.reviews]);
        setCount(res.data.count);
      }
      setReviewLoading(false);
    };

    if (offset > 0) {
      fetchRecipeReviews();
    }
  }, [offset, recipeId]);

  useEffect(() => {
    const initialFetch = async () => {
      setReviewLoading(true);
      const res = await getRecipeReviews(recipeId, 5);

      if (res.status === 200) {
        setReviews(res.data.reviews);
        setCount(res.data.count);
      }
      setReviewLoading(false);
    };

    initialFetch();
  }, [recipeId]);

  const handleLoadMoreReviews = () => {
    setOffset(offset + 5);
  };

  useEffect(() => {
    setHasMore(reviews.length < count);
  }, [reviews, count]);

  return !recipeLoading && Object.keys(recipe).length > 0 ? (
    <>
      <Seo
        title={recipe?.title}
        description={recipe?.description}
        type="article"
        image={recipe?.image}
        url={window.location.href}
      />

      <div className="main-section sm:px-6 sm:py-0 pb-10 ">
        <Header
          recipeId={recipeId}
          image={recipe?.image}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          admin={recipe?.user_id === userId}
        />
        <div className=" max-w-[600px] w-full mx-auto bg-white px-4">
          <Post
            createdDate={recipe?.createdAt}
            title={recipe?.title}
            username={recipe?.user?.username}
            userImage={recipe?.user?.image}
            totalRating={recipe?.average_rating}
            NumberOfReviews={recipe?.review_count}
            description={recipe?.description}
            prepTime={recipe?.prep_time}
            cookTime={recipe?.cook_time}
            servings={recipe?.servings}
            difficulty={recipe?.difficulty_level}
            ingredients={recipe?.ingredients}
            instructions={recipe?.instructions}
          />
          <AddRating
            reviewsData={reviews}
            setReviews={setReviews}
            setCount={setCount}
          />
          <UserRating
            numOfReviews={count}
            setCount={setCount}
            reviewsData={reviews}
            onLoadMore={handleLoadMoreReviews}
            isLoadMore={hasMore}
            setReviews={setReviews}
            isLoading={reviewLoading}
          />
        </div>
        <Footer />
      </div>
    </>
  ) : (
    <MainLoader />
  );
}

export default Recipe;
