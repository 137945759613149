import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import GetRating from "../../util/GetRating";

const RecipesList = React.memo(
  React.forwardRef(
    (
      {
        id,
        title,
        category,
        imageUrl,
        username,
        userImage,
        totalRating,
        NumberOfReviews,
      },
      ref
    ) => {
      const { t } = useTranslation();
      const [isImageLoaded, setIsImageLoaded] = useState(false);

      useEffect(() => {
        const img = new Image();
        img.src = `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`;
        img.onload = () => setIsImageLoaded(true);
      }, [imageUrl]);

      return (
        <li
          ref={ref}
          key={id}
          className="relative rounded-lg overflow-hidden bg-white shadow-md text-gray-800 mb-4 sm:mb-0"
        >
          <div to={`/recipe/${id}`} className="block">
            <div className="relative">
              {!isImageLoaded && (
                <div className="bg-gray-300 h-48 sm:h-48 animate-pulse absolute inset-0"></div>
              )}
              <NavLink to={`/recipe/${id}`}>
                <img
                  src={`${process.env.REACT_APP_ASSET_URL}/${imageUrl}`}
                  alt={title}
                  className="w-full recipes_post_size  sm:h-48 object-cover"
                />
              </NavLink>
              <div className="absolute bottom-0 left-0 w-full px-3 pb-2 pt-3 bg-gradient-to-t from-black to-transparent">
                <NavLink to={`/recipe/${id}`}>
                  <h3 className="text-lg text-white mb-1">{title}</h3>
                </NavLink>
                <NavLink to={`/${category}`}>
                  <p className="text-gray-300 text-sm">{t(category)}</p>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 flex items-center border-t border-gray-200">
            <div className="overflow-hidden bg-gray-300 rounded-full w-10 h-10">
              <NavLink to={`/author/${username}`}>
                <img
                  src={`${process.env.REACT_APP_ASSET_URL}/${userImage}`}
                  alt=""
                  className="h-10 w-10 object-cover"
                />
              </NavLink>
            </div>
            <div className="ml-4">
              <div className="font-semibold text-sm">
                <NavLink to={`/author/${username}`}>{username}</NavLink>
              </div>
              <GetRating
                className="w-5 h-5"
                reviews={NumberOfReviews}
                ratingStar={totalRating}
              />
            </div>
          </div>
        </li>
      );
    }
  )
);

export default RecipesList;
