import React, { createContext } from "react";
import { SideMenuContext, SideMenuProvider } from "./sideMenuContext";
import { AuthContext, AuthProvider } from "./authContext";
import { SaveContext, SaveProvider } from "./saveContext";

export const AppContext = createContext();

export function AppProvider({ children }) {
  return (
    <SideMenuProvider>
      <AuthProvider>
        <SaveProvider>
          <AppContext.Provider
            value={{
              sideMenu: SideMenuContext,
              auth: AuthContext,
              saveRecipe: SaveContext,
            }}
          >
            {children}
          </AppContext.Provider>
        </SaveProvider>
      </AuthProvider>
    </SideMenuProvider>
  );
}
