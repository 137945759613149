import React, { createContext, useContext, useEffect, useState } from "react";
import { getSavedRecipeIds } from "../api/request";
import { AuthContext } from "./authContext";

export const SaveContext = createContext({
  ids: [],
  addFavorite: (id) => {},
  removeFavorite: (id) => {},
});

export const SaveProvider = ({ children }) => {
  const { token, isLoggedIn } = useContext(AuthContext);
  const [favoriteMealIds, setFavoriteMealIds] = useState([]);

  useEffect(() => {
    if (!isLoggedIn) {
      setFavoriteMealIds([]);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const getSavedIds = async () => {
      try {
        const response = await getSavedRecipeIds(token);

        if (response.status === 200) {
          setFavoriteMealIds(response.data);
        }
      } catch (error) {}
    };
    getSavedIds();
  }, [token, isLoggedIn]);

  function addFavorite(id) {
    setFavoriteMealIds((currentFavID) => [...currentFavID, id]);
  }

  function removeFavorite(id) {
    setFavoriteMealIds((currentFavID) =>
      currentFavID.filter((mealId) => mealId !== id)
    );
  }

  return (
    <SaveContext.Provider
      value={{
        ids: favoriteMealIds,
        addFavorite: addFavorite,
        removeFavorite: removeFavorite,
      }}
    >
      {children}
    </SaveContext.Provider>
  );
};
