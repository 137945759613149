import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./WarningModal.module.css";

const WarningModal = ({
  onClose,
  onClick,
  message,
  name_close,
  name_click,
  close_btn_bg,
  click_btn_bg,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div onClick={onClose} className={styles.modal_wrapper}>
        <div className={styles.modal}>
          {t(message)}
          {/* <p>Please login to save recipe!</p> */}
          <button
            onClick={onClose}
            style={{ backgroundColor: close_btn_bg }}
            className={` px-4 py-1 text-white rounded-md mt-3`}
          >
            {t(name_close)}
          </button>
          {name_click && (
            <button
              onClick={onClick}
              style={{ backgroundColor: click_btn_bg }}
              className={`px-4 py-1 text-white rounded-md mt-3`}
            >
              {t(name_click)}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
