import React from "react";
import { RatingIcon } from "../assets/Icons/PageIcons";
import classes from "./util.module.css";

const GetRating = ({ className, reviews, ratingStar, onReviewLoad }) => {
  const rating = ratingStar;
  return (
    <div className=" flex items-center gap-1 ">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={
              index <= rating
                ? `${classes.on} cursor-default`
                : `${classes.off} cursor-default`
            }
          >
            <RatingIcon className={className} />
          </button>
        );
      })}
      {!onReviewLoad && <p className=" text-xs text-gray-500 ">({reviews})</p>}
    </div>
  );
};

export default GetRating;
