import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getUserProfile, getUserRecipeByUsername } from "../../api/request";
import {
  UserProfileSkeleton,
  UserRecipeListSkeleton,
} from "../../components/UI/Skeleton";
import { AuthContext } from "../../context/authContext";
import { Seo } from "../../util/Seo";
import Profile from "./Profile";
import UserLayout from "./UserLayout";
import UserPost from "./UserPost";

const Author = () => {
  const { userId } = useContext(AuthContext);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [myRecipes, setMyRecipes] = useState([]);
  const [profile, setProfile] = useState([]);

  const { username } = useParams();
  useEffect(() => {
    const fetchUserRecipeAndProfile = async () => {
      try {
        const [recipesResponse, profileResponse] = await Promise.all([
          getUserRecipeByUsername(username),
          getUserProfile(username),
        ]);
        if (recipesResponse.status === 200) {
          setMyRecipes(recipesResponse.data);
          setIsLoading(false);
        }
        if (profileResponse.status === 200) {
          setProfile(profileResponse.data);
          setIsProfileLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setIsProfileLoading(false);
      }
    };
    fetchUserRecipeAndProfile();
  }, [username]);

  return (
    <UserLayout>
      <Seo
        title={profile?.username}
        description="View and manage your recipes on Gebeta Recipes."
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url={window.location.href}
      />
      <div className="sm:max-w-lg px-2 sm:px-0 m-auto  w-full ">
        {isProfileLoading ? (
          <UserProfileSkeleton />
        ) : (
          <Profile
            username={`${profile.first_name} ${profile.last_name}`}
            avatarUrl={`${process.env.REACT_APP_ASSET_URL}/${profile.image}`}
            post={myRecipes.length || `no`}
          />
        )}
        <div className=" w-full h-[2px] bg-slate-200" />
        <div className=" w-full">
          <ul className="mt-8 pb-4 ">
            {isLoading ? (
              <>
                <UserRecipeListSkeleton />
                <UserRecipeListSkeleton />
                <UserRecipeListSkeleton />
              </>
            ) : myRecipes.length > 0 ? (
              <UserPost
                myRecipes={myRecipes}
                setMyRecipes={setMyRecipes}
                admin={profile.user_id === userId}
              />
            ) : (
              <div className=" bg-slate-300">
                <h1 className=" text-center text-lg py-9 text-white font-bold">
                  {t("NO RECIPES")}
                </h1>
              </div>
            )}
          </ul>
        </div>
      </div>
    </UserLayout>
  );
};

export default Author;
