const { default: axios } = require("./axios");

export const addRecipe = async (token, body) => {
  const formData = new FormData();
  formData.append("image", body.image);
  formData.append("title", body.title);
  formData.append("category", body.category);
  formData.append("description", body.description);
  formData.append("prep_time", body.prep_time);
  formData.append("cook_time", body.cook_time);
  formData.append("difficulty", body.difficulty);
  formData.append("servings", body.servings);
  formData.append("ingredients", body.ingredients);
  formData.append("directions", body.directions);
  formData.append("tags", body.tags);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  const res = await axios.post("recipes/add-recipes", formData, config);
  return res;
};

export const addCategory = async (token, body) => {
  const formData = new FormData();
  formData.append("image", body.image);
  formData.append("name", body.name);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  const res = await axios.post("recipes/categories", formData, config);
  return res;
};

export const updateRecipe = async (token, recipeId, body) => {
  const formData = new FormData();
  formData.append("image", body.image);
  formData.append("title", body.title);
  formData.append("category", body.category);
  formData.append("description", body.description);
  formData.append("prep_time", body.prep_time);
  formData.append("cook_time", body.cook_time);
  formData.append("difficulty", body.difficulty);
  formData.append("servings", body.servings);
  formData.append("ingredients", body.ingredients);
  formData.append("directions", body.directions);
  formData.append("tags", body.tags);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  const res = await axios.patch(`recipes/${recipeId}`, formData, config);
  return res;
};

export const deleteRecipe = async (token, recipeId) => {
  const res = await axios.delete(`recipes/${recipeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
  return res;
};

export const getRecipeById = async (recipeId) => {
  const res = await axios.get(`recipes/${recipeId}`);
  return res;
};

export const getAllLatestRecipes = async (page = 1) => {
  const res = await axios.get(`recipes/latest`, { params: { page } });
  return res;
};
export const getAllRecipesByReview = async () => {
  const res = await axios.get("recipes/by-review");
  return res;
};
//

export const getRecipesByCategory = async (categoryName, orderBy) => {
  const res = await axios.get(`recipes/bycategory/${categoryName}`, {
    params: { orderBy },
  });
  return res;
};

export const getSavedRecipes = async (token) => {
  try {
    const res = await axios.get("save", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getSavedRecipeIds = async (token) => {
  try {
    const res = await axios.get("save/ids", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
};

export const saveOrUnsaveRecipe = async (token, recipe_id) => {
  try {
    const res = await axios.post(
      "save",
      {
        recipe_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getUserRecipe = async (token) => {
  try {
    const res = await axios.get("recipes/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getUserRecipeByUsername = async (username) => {
  try {
    const res = await axios.get(`recipes/username/${username}`);
    return res;
  } catch (error) {
    return error.response;
  }
};

// USER REQUEST

export const getUserProfile = async (username) => {
  try {
    const res = await axios.get(`users/${username}`);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const updateProfile = async (token, body) => {
  const formData = new FormData();
  formData.append("image", body.image);
  formData.append("first_name", body.first_name);
  formData.append("last_name", body.last_name);
  formData.append("username", body.username);
  // formData.append("email", body.email);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  const res = await axios.patch(`users`, formData, config);
  return res;
};

export const resetPassword = async (body, token) => {
  const res = await axios.patch("users/reset-password", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });

  return res;
};

// REVIEW REQUEST
export const getRecipeReviews = async (recipeId, limit, offset = 0) => {
  const res = await axios.get(
    `review/${recipeId}?limit=${limit}&offset=${offset}`
  );
  return res;
};

export const createReview = async (token, body) => {
  const res = await axios.post("review", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
  return res;
};

export const deleteReview = async (token, reviewId) => {
  const res = await axios.delete(`review/${reviewId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
  return res;
};

// LVE SEARCH
export const liveSearch = async (q) => {
  const res = await axios.get(`recipes/search?query=${q}`);
  return res;
};
