import { NavLink } from "react-router-dom";
import GetRating from "../../util/GetRating";
import { formatDate } from "../../util/formatDate";
import { useContext, useState } from "react";
import { deleteReview } from "../../api/request";
import { AuthContext } from "../../context/authContext";
import Notify from "../../components/UI/Notify";
import OptionDropDown from "../../components/UI/OptionDropDown";
import { useTranslation } from "react-i18next";
import ContentSpinner from "../../components/UI/ContentSpinner";

const UserRating = ({
  numOfReviews,
  setCount,
  reviewsData,
  onLoadMore,
  setReviews,
  isLoadMore,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { token, userId } = useContext(AuthContext);

  const [showNotify, setShowNotify] = useState(false);

  const reviewDeleteHandler = async (reviewId) => {
    try {
      await deleteReview(token, reviewId);
      setReviews(reviewsData.filter((review) => review.review_id !== reviewId));
      setCount((prev) => prev - 1);
      setShowNotify(true);
    } catch (error) {}
  };

  const notifyCloseHandler = () => {
    setShowNotify(false);
  };

  return (
    <>
      {showNotify && (
        <Notify
          message="Review Deleted successfully"
          type="success"
          onClose={notifyCloseHandler}
        />
      )}
      <div className="pb-8 sm:mb-8">
        <div className=" border-b border-gray-100 pb-2">
          <h3 className=" text-lg pb-1 font-bold">
            {t("Reviews")} ({numOfReviews})
          </h3>
        </div>
        <ul className="mt-4">
          {reviewsData.map((review, index) => (
            <li
              key={index}
              className="relative flex items-center justify-between border-b border-gray-100 pb-3 mt-3"
            >
              <div>
                <div className="flex items-center pb-3">
                  <div className="overflow-hidden rounded-full w-8 h-8">
                    <NavLink to={`/author/${review?.User?.username}`}>
                      <img
                        src={`${process.env.REACT_APP_ASSET_URL}/${review?.User?.image}`}
                        alt="user_profile_picture"
                        className=" h-8 object-cover"
                      />
                    </NavLink>
                  </div>
                  <div className="flex-grow pl-3 ">
                    <div className="font-bold text-sm">
                      <NavLink to={`/author/${review?.User?.username}`}>
                        {review?.User?.first_name} {review?.User?.last_name}
                      </NavLink>
                    </div>
                    <p className="text-xs text-gray-600">
                      {formatDate(review?.createdAt)}
                    </p>
                  </div>
                </div>
                <GetRating
                  onReviewLoad
                  className="sm:w-5 sm:h-5 w-4 h-4 "
                  ratingStar={review?.rating}
                />
                <p className="text-sm pt-1">{review?.comment}</p>
              </div>
              {review?.User?.user_id === userId && (
                <OptionDropDown
                  onClickDelete={() => reviewDeleteHandler(review?.review_id)}
                />
              )}
            </li>
          ))}
        </ul>
        {isLoadMore && !isLoading && (
          <div className="flex justify-center">
            <button
              onClick={onLoadMore}
              className=" text-gray-500 text-sm mt-2"
            >
              Load More Reviews
            </button>
          </div>
        )}
        {isLoading && <ContentSpinner />}
      </div>
    </>
  );
};

export default UserRating;
