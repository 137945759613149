export const CategorySkeleton = () => {
  return (
    <li className="flex flex-none flex-col items-center space-y-1">
      <div className="block bg-gray-200 p-1 rounded-full relative animate-pulse">
        <div className="block bg-gray-50 p-1 rounded-full relative ">
          <div className="sm:w-16 sm:h-16 w-14 h-14 rounded-full bg-gray-200"></div>
        </div>
      </div>
      <div className="text-xs text-gray-600 font-semibold animate-pulse">
        <div className="h-4 w-16 sm:h-3  bg-gray-200 rounded"></div>
      </div>
    </li>
  );
};

export const UserProfileSkeleton = () => {
  return (
    <div className="flex items-center justify-center py-3">
      <div className="sm:w-24 sm:h-24 w-20 h-20 bg-gray-300 rounded-full animate-pulse"></div>
      <div className="ml-4">
        <div className="sm:text-md text-sm font-bold capitalize bg-gray-300 w-28 h-5 animate-pulse"></div>
        <div className="text-sm bg-gray-300 w-20 h-3 mt-1 animate-pulse"></div>
      </div>
    </div>
  );
};

export const UserRecipeListSkeleton = () => {
  return (
    <li className=" relative rounded-md  ml-1 sm:ml-0 mb-6">
      <div className="grid grid-cols-[128px_minmax(128px,_1fr)] gap-1 sm:gap-3">
        <div className="w-[120px] h-[80px] rounded-md animate-pulse bg-gray-300"></div>
        <div className="relative my-auto flex justify-between gap-2">
          <div>
            <div className="mb-1 leading-3 sm:leading-none capitalize text-sm font-semibold pb-1 animate-pulse bg-gray-300"></div>
            <div className="mb-1 sm:w-4 sm:h-4 w-4 h-4 animate-pulse bg-gray-300"></div>
            <div className="mb-1 text-xs pt-1 animate-pulse bg-gray-300"></div>
          </div>
        </div>
      </div>
    </li>
  );
};

export const EditProfileSkeleton = () => {
  return (
    <div className="animate-pulse flex flex-col h-full">
      <div className="w-32 mx-auto">
        <div className="relative">
          <div className="w-full h-32 bg-gray-200 rounded-full"></div>
          <div className="mt-2">
            <div className="hidden"></div>
          </div>
        </div>
        <div className="text-xs pt-2 text-red-600"></div>
      </div>
      <div className="w-64 mx-auto mt-4">
        <div className="flex flex-col">
          <div className="bg-gray-200 py-2 px-3 rounded-full w-full mt-2 h-8"></div>
          <div className="text-xs text-red-600"></div>
          <div className="bg-gray-200 py-2 px-3 rounded-full w-full mt-2 h-8"></div>
          <div className="text-xs text-red-600"></div>
          <div className="bg-gray-200 py-2 px-3 rounded-full w-full mt-2 h-8"></div>
          <div className="text-xs text-red-600"></div>
          <div className="bg-gray-200 py-2 px-3 rounded-full w-full mt-2 h-8"></div>
          <div className="text-xs text-red-600"></div>
        </div>
      </div>
      <div className="mx-auto mt-4">
        <div className="w-28 h-9 bg-gray-200 rounded-full"></div>
      </div>
    </div>
  );
};
