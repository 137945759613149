import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { i18n } = useTranslation();
  const dropdownRef = useRef(null);

  const languages = [
    { code: "en", name: "English" },
    { code: "am", name: "አማርኛ" },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectLanguage = (code) => {
    i18n.changeLanguage(code);
    setIsDropdownOpen(false);
    localStorage.setItem("language", code);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Set the initial language to the saved language code
    }
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative inline-block text-left " ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="rounded-full inline-flex justify-center w-full border border-gray-300 shadow-sm text-xs px-2 py-1 bg-white text-[10px] sm:text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:focus:ring-2 sm:focus:ring-offset-2 sm:focus:ring-offset-gray-100 sm:focus:ring-secondary"
          onClick={toggleDropdown}
        >
          <span>
            {languages.find((lang) => lang.code === i18n.language)?.name ||
              "Select language"}
          </span>
          <svg
            className={`ml-1 h-4 w-4 transform transition-transform duration-300 ${
              isDropdownOpen ? "-rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path fillRule="evenodd" d="M10 13l-5-5h10l-5 5z" />
          </svg>
        </button>
      </div>

      {isDropdownOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-26 text-xs rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {languages.map((lang) => (
              <button
                key={lang.code}
                onClick={() => selectLanguage(lang.code)}
                className="w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                role="menuitem"
              >
                <span className="mr-2">{lang.flag}</span>
                {lang.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
