import React from "react";
import { useTranslation } from "react-i18next";
import { RemoveItemIcon } from "../../assets/Icons/PageIcons";

const IngredientsList = ({ inputFields, onIngredientInputChange }) => {
  const { t } = useTranslation();
  const addInputField = () => {
    onIngredientInputChange([
      ...inputFields,
      {
        ingredient: "",
        isValid: true,
        showIngRequiredMessage: false,
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    onIngredientInputChange(rows);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const list = [...inputFields];
    list[index][name] = value;
    onIngredientInputChange(list);
  };

  const handleBlur = (index) => {
    const list = [...inputFields];
    if (list[index].ingredient) {
      list[index].isValid = true;
      list[index].showIngRequiredMessage = false;
    } else {
      list[index].isValid = false;
      list[index].showIngRequiredMessage = true;
    }
    onIngredientInputChange(list);
  };

  return (
    <div className="mb-6">
      <label
        htmlFor="exampleFormControlInput1"
        className="form-label inline-block mb-2 text-gray-700 font-semibold"
      >
        {t("Ingredients")}*
      </label>

      <div className=" relative flex">
        <div className=" w-1 h-auto bg-slate-100" />
        <ul className="pl-3 w-full">
          {inputFields.map((data, index) => (
            <li
              key={index}
              className={`${data.showIngRequiredMessage && "invalid"} relative`}
            >
              <input
                name="ingredient"
                type="text"
                placeholder={t("e.g ¼ cup shiro")}
                value={data.ingredient || ""}
                onChange={(e) => handleChange(index, e)}
                onBlur={() => handleBlur(index)}
                className=" add-recipe-input !mb-2"
              />
              {index ? (
                <span
                  className=" remove-list-btn"
                  onClick={() => removeInputFields(index)}
                >
                  <RemoveItemIcon width="22" height="22" />
                </span>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
      {inputFields.length < 13 ? (
        <button
          type="button"
          onClick={() => addInputField()}
          className="add-list-btn"
        >
          {t("Add Ingredient")}
        </button>
      ) : null}
    </div>
  );
};

export default IngredientsList;
