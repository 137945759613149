import { useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import RecipesList from "./RecipesList";
import Category from "./Category";
import SortPost from "./SortPost";
import MainLoader from "../../components/UI/MainLoader";
import { getAllRecipes, getAllRecipesByCategory } from "../../api/axios";
import { useInfiniteQuery } from "react-query";
import ScrollToTop from "../../components/UI/ScrollToTop";
import ContentSpinner from "../../components/UI/ContentSpinner";
import { Seo } from "../../util/Seo";

const options = [
  { value: "latest", label: "Latest" },
  { value: "popular", label: "Popular" },
];

const HomePage = () => {
  const { category } = useParams();

  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    status,
    error,
  } = useInfiniteQuery(
    ["recipes", category, selectedOption],
    ({ pageParam = 1 }) => {
      // console.log(pageParam);
      if (category) {
        return getAllRecipesByCategory(pageParam, category, selectedOption);
      } else {
        return getAllRecipes(pageParam, selectedOption);
      }
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length ? allPages.length + 1 : undefined;
      },
    }
  );

  const handleChange = useCallback((event) => {
    setSelectedOption(event.target.value);
    intObserver.current?.disconnect();
  }, []);

  const intObserver = useRef();
  const lastPostRef = useCallback(
    (post) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          // console.log("We are near the last post!");
          fetchNextPage();
        }
      });

      if (post) intObserver.current.observe(post);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const content = useMemo(() => {
    return (
      <>
        {data?.pages.map((pg) =>
          pg.map((post, i) => (
            <RecipesList
              ref={pg.length === i + 1 ? lastPostRef : null}
              key={post?.recipe_id}
              id={post?.recipe_id}
              title={post?.title}
              category={post?.category?.category_name}
              imageUrl={post?.image}
              username={post?.user?.username}
              userImage={post?.user?.image}
              totalRating={post?.average_rating}
              NumberOfReviews={post?.review_count}
            />
          ))
        )}
      </>
    );
  }, [data?.pages, lastPostRef]);

  if (status === "loading") return <MainLoader />;

  return (
    <div className="main-section sm:py-3">
      <Seo
        title="Gebeta Recipes"
        description="Explore our collection of delicious and authentic Ethiopian and other recipes at Gebeta Recipes."
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url="https://gebetarecipes.com"
      />
      <Category />
      <div className="sm:px-6">
        <SortPost
          onSelect={handleChange}
          options={options}
          selectedOption={selectedOption}
        />

        <div className="px-1 pt-2 sm:pt-0 z-10 ">
          {/* sm:auto-rows-[281px] */}
          <ul className="sm:mb-8 sm:mt-4 pb-24 sm:auto-rows-auto grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {status === "error" ? (
              <p className="text-md text-red-600 text-center mt-20">
                Error:
                {error?.response?.status ? "Page Not Found" : error.message}
              </p>
            ) : (
              <>
                {content}
                {isFetchingNextPage && <ContentSpinner />}
              </>
            )}
          </ul>
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default HomePage;
