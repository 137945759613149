import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { liveSearch } from "../../api/request";
import { CloseIcon } from "../../assets/Icons/PageIcons";
import { SideMenuContext } from "../../context/sideMenuContext";

function SearchBar() {
  const { expandedSearch, handleSearchButtonClick } =
    useContext(SideMenuContext);

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({
    recipes: [],
    users: [],
  });

  const handleInputChange = async (event) => {
    setSearchText(event.target.value);
    try {
      setLoading(true);
      const res = await liveSearch(event.target.value);
      const { recipes = [], users = [] } = res.data;

      const updatedSearchResults = { recipes, users };
      setSearchResults(updatedSearchResults);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div
      className={`bg-white w-full md:max-w-[300px] lg:max-w-[450px]  md:py-0 ${
        expandedSearch ? "fixed md:relative z-50  block    " : "hidden md:block"
      }`}
    >
      <div className=" flex justify-center gap-2">
        <div className={`relative w-full `}>
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <svg
              className="h-5 w-5 text-gray-500"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21L15.5 15.5"
              />
              <path
                d="M15.5 9C15.5 11.9853 12.9853 14.5 10 14.5C7.01472 14.5 4.5 11.9853 4.5 9C4.5 6.01472 7.01472 3.5 10 3.5C12.9853 3.5 15.5 6.01472 15.5 9Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </span>

          <input
            type="text"
            value={searchText}
            onChange={(e) => handleInputChange(e)}
            className="block text-black w-full pl-10  pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm transition duration-150 ease-in-out"
            placeholder="Search"
          />
          {loading && (
            <span className="absolute inset-y-0 right-0 pr-1 flex items-center">
              <div className="MYspinner"></div>
            </span>
          )}
        </div>
        {expandedSearch && (
          <button
            onClick={handleSearchButtonClick}
            className=" bg-secondary rounded-md flex justify-center items-center text-black mr-5 w-10"
          >
            <CloseIcon fill="white" />
          </button>
        )}
      </div>

      {searchText && (
        <div
          className={`absolute pb-5 top-full left-0 right-5 md:right-52 md:left-60  max-h-screen sm:max-h-96  overflow-y-auto mt-2 border border-gray-300 rounded-b-md bg-white 
            
            `}
        >
          {searchResults?.recipes?.length > 0 ||
          searchResults?.users?.length > 0 ? (
            searchResults.recipes
              .map((result) => (
                <NavLink
                  to={`/recipe/${result.recipe_id}`}
                  onClick={() => setSearchText("")}
                  key={result.recipe_id}
                  className="py-1"
                >
                  <div className="flex items-center px-4 py-2 hover:bg-gray-100">
                    <img
                      src={`${process.env.REACT_APP_ASSET_URL}/${result.image}`}
                      alt="recipe_image"
                      className="w-11 h-9 rounded-md mr-2 border border-gray-300"
                    />
                    <div className="flex-col">
                      <p className="block text-sm text-gray-700">
                        {result.title}
                      </p>
                      <p className="block text-xs text-gray-600">recipe</p>
                    </div>
                  </div>
                </NavLink>
              ))
              .concat(
                searchResults.users.map((result) => (
                  <NavLink
                    to={`/author/${result.username}`}
                    onClick={() => setSearchText("")}
                    key={result.username}
                    className="py-1"
                  >
                    <div className="flex items-center px-4 py-2 hover:bg-gray-100">
                      <img
                        src={`${process.env.REACT_APP_ASSET_URL}/${result.image}`}
                        alt="profile_image"
                        className="w-11 h-11 rounded-full mr-2 border border-gray-300"
                      />
                      <div className="flex-col">
                        <p className="block text-sm text-gray-700">
                          {result.username}
                        </p>
                        <p className="block text-xs text-gray-600">user</p>
                      </div>
                    </div>
                  </NavLink>
                ))
              )
          ) : (
            <p className="px-4 py-2 text-gray-700">No results found</p>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
