import { useContext, useState } from "react";
import { useFormik } from "formik";
import UserLayout from "../User/UserLayout";
import { PasswordHide, PasswordShow } from "../../assets/Icons/PageIcons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import Button from "../../components/UI/Button";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";
import { Seo } from "../../util/Seo";

const validate = (values) => {
  const errors = {};

  if (!values.phoneEmail) {
    errors.phoneEmail = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

const Login = () => {
  const { t } = useTranslation();
  const { login } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [msg, setMsg] = useState("");

  const navigate = useNavigate();
  const { state } = useLocation();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const formik = useFormik({
    initialValues: {
      phoneEmail: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      setLoading(true);
      setMsg("");

      try {
        const response = await axios.post(
          "users/login",
          {
            email_or_username: values.phoneEmail,
            password: values.password,
          },
          { withCredentials: true }
        );
        const { token, userId, username, phoneEmail, image } = response.data;

        setLoading(false);
        login(token, userId, username, phoneEmail, image);
        navigate(state?.path || "/", { replace: true });
      } catch (error) {
        setLoading(false);
        setMsg(error.response.data.message);
        error.response.data.errors.forEach((err) => {
          formik.errors[err.param] = err.msg;
        });
      }
    },
  });

  return (
    <UserLayout>
      <Seo
        title="Login | Gebeta Recipes"
        description="Login to Gebeta Recipes and discover a world of delicious recipes."
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url="https://gebetarecipes.com/login"
      />
      <form
        onSubmit={formik.handleSubmit}
        className="max-w-md mx-auto px-8 my-8"
      >
        <h1 className="text-xl text-secondary mb-4 text-center font-bold">
          {t("Log in")}
        </h1>

        <div className="mb-4">
          <label
            htmlFor="phoneEmail"
            className="block text-gray-700 font-bold mb-1 text-sm"
          >
            {t("Phone Number")} | {t("Email or Username")}
          </label>
          <input
            type="text"
            id="phoneEmail"
            name="phoneEmail"
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              formik.touched.phoneEmail && formik.errors.phoneEmail
                ? "border-red-500"
                : ""
            }`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneEmail}
          />
          {formik.touched.phoneEmail && formik.errors.phoneEmail && (
            <p className="text-red-500 text-xs italic">
              {t(formik.errors.phoneEmail)}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-gray-700 font-bold mb-1 text-sm"
          >
            {t("Password")}
          </label>
          <div className="relative">
            <input
              type={passwordShown ? "text" : "password"}
              id="password"
              name="password"
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formik.touched.password && formik.errors.password
                  ? "border-red-500"
                  : ""
              }`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={togglePassword}
            >
              {passwordShown ? (
                <PasswordShow className=" w-4 h-4" fill="#ee5873" />
              ) : (
                <PasswordHide className=" w-4 h-4" fill="#ee5873" />
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <p className="text-red-500 text-xs italic">
              {t(formik.errors.password)}
            </p>
          )}
        </div>

        {msg && (
          <div className="text-red-500 text-sm mt-1 text-center mb-1">
            {t(msg)}
          </div>
        )}

        <Button
          className="h-9 mb-2 w-full bg-secondary hover:bg-btnHover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
          text="Log In"
          loading={loading}
        />

        <div className="flex justify-between items-center">
          <div className="text-sm font-medium text-gray-500 ">
            <span className=" hidden sm:inline-block pr-1">
              {t("Not registered?")}
            </span>
            <NavLink
              to="/signup"
              className="text-secondary text-sm hover:underline "
            >
              {t("Create account")}
            </NavLink>
          </div>
          <NavLink
            to="/password-reset"
            className="text-sm text-secondary hover:underline "
          >
            {t("Lost Password?")}
          </NavLink>
        </div>
      </form>
    </UserLayout>
  );
};

export default Login;
