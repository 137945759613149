import { useTranslation } from "react-i18next";
import styles from "./Button.module.css";
import Spninner from "./Spninner";

const Button = ({
  onClick,
  type,
  text,
  loading = false,
  className,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={` ${styles.spinner_button} ${className} `}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {!loading ? t(text) : <Spninner />}
    </button>
  );
};

export default Button;
