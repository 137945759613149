import React, { useState } from "react";
import imageCompression from "browser-image-compression";

import HeaderImage from "../../components/UI/HeaderImage";
import { useTranslation } from "react-i18next";
import ImagePostLoader from "../../components/UI/ImagePostLoader";

const ImageInput = ({ onImageChange, onImagePreview, imagePreviewUrl }) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!/.(jpeg|jpg|png)$/i.test(file.name)) {
      setError("Invalid file type, only jpg, jpeg, and png are allowed.");
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      setError("File size is too large, maximum size is 10 MB.");
      return;
    }

    try {
      setIsLoading(true);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      onImageChange(compressedFile);

      const compressedImage = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
      onImagePreview(compressedImage);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError("Failed to compress image. Please try again later.");
      setIsLoading(false);
    }
    setError("");
  };

  return (
    <div>
      {isLoading && <ImagePostLoader className={"sm:h-60 h-40"} />}
      {imagePreviewUrl && !isLoading && (
        <div>
          <HeaderImage add img={imagePreviewUrl}>
            <div className="absolute bottom-5 right-5 flex-col z-10 flex items-center gap-4">
              <button
                type="button"
                className=" rounded-md text-xs sm:text-sm bg-light px-3 py-1 sm:hover:bg-light2"
                onClick={() => {
                  document.getElementById("imageInput").click();
                }}
              >
                {t("Upload Image")}
              </button>
            </div>
          </HeaderImage>
        </div>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
      <input
        type="file"
        id="imageInput"
        accept=".jpg,.png,.jpeg"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ImageInput;
