import {
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  YoutubeIcon,
} from "../../assets/Icons/PageIcons";

const ContactSocial = () => {
  return (
    <div className=" flex justify-center  absolute inset-0  z-10">
      <div className="   flex justify-center items-center my-auto gap-4">
        <a
          href="https://www.facebook.com/gebetaa"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary hover:text-secondary transition ease-out duration-500"
        >
          <FacebookIcon
            className="cursor-pointer inline-block h-6 w-6"
            width="24"
            height="24"
          />
        </a>
        <a
          href="https://www.instagram.com/gebetaa_recipes"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary hover:text-secondary transition ease-out duration-500"
        >
          <InstagramIcon
            className="cursor-pointer inline-block h-6 w-6"
            width="24"
            height="24"
          />
        </a>
        <a
          href="https://www.youtube.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary hover:text-secondary transition ease-out duration-500"
        >
          <YoutubeIcon
            className="cursor-pointer inline-block h-6 w-6"
            width="24"
            height="24"
          />
        </a>
        <a
          href="https://www.tiktok.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary hover:text-secondary transition ease-out duration-500"
        >
          <TiktokIcon
            className="cursor-pointer inline-block h-6 w-6"
            width="24"
            height="24"
          />
        </a>
      </div>
    </div>
  );
};

export default ContactSocial;
