import React from "react";
import Form from "./Form";

let defaultImage = `${process.env.REACT_APP_ASSET_URL}/uploads/gebeta/addRecipeImageTemp.webp`;

export function Add() {
  return (
    <>
      <Form RecipeImage={defaultImage} page="add" />
    </>
  );
}

export default Add;
