import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { deleteRecipe } from "../../api/request";

import Notify from "../../components/UI/Notify";
import OptionDropDown from "../../components/UI/OptionDropDown";
import WarningModal from "../../components/UI/WarningModal";
import { AuthContext } from "../../context/authContext";
import { formatDate } from "../../util/formatDate";
import GetRating from "../../util/GetRating";

const UserPost = ({ myRecipes, setMyRecipes, admin }) => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecipeId, setSelectedRecipeId] = useState(null);
  const [showNotify, setShowNotify] = useState(false);

  const recipeDeleteHandler = async (recipeId) => {
    try {
      const res = await deleteRecipe(token, recipeId);

      if (res.status === 200) {
        setMyRecipes(
          myRecipes.filter((recipe) => recipe.recipe_id !== recipeId)
        );
        setSelectedRecipeId(null);
        setShowNotify(true);
      }
    } catch (error) {}
  };

  const notifyCloseHandler = () => {
    setShowNotify(false);
  };

  return (
    <>
      {showDeleteModal && (
        <WarningModal
          onClose={() => {
            setShowDeleteModal(false);
            setSelectedRecipeId(null);
          }}
          onClick={() => recipeDeleteHandler(selectedRecipeId)}
          message="Are you sure to delete?"
          name_close="No"
          name_click="Yes"
          close_btn_bg="#097969"
          click_btn_bg="#CB4335"
        />
      )}
      {showNotify && (
        <Notify
          message="Recipe Deleted Successfully"
          type="success"
          onClose={notifyCloseHandler}
        />
      )}

      {myRecipes.map((recipe) => (
        <li
          key={recipe.recipe_id}
          className=" relative rounded-md  ml-1 sm:ml-0 mb-6"
        >
          <div className="grid grid-cols-[128px_minmax(128px,_1fr)] gap-1 sm:gap-3">
            <NavLink
              to={`/recipe/${recipe.recipe_id}`}
              className=" w-[120px] h-[80px] rounded-md overflow-hidden"
            >
              <img
                src={`${process.env.REACT_APP_ASSET_URL}/${recipe.image}`}
                alt="liked"
                className=" h-[80px] object-cover"
              />
            </NavLink>
            <div className=" relative  my-auto  flex justify-between gap-2 ">
              <div>
                <NavLink
                  to={`/recipe/${recipe.recipe_id}`}
                  className=" leading-3 sm:leading-none capitalize text-sm font-semibold pb-1"
                >
                  {recipe.title}
                </NavLink>
                <GetRating
                  className="sm:w-4 sm:h-4 w-4 h-4 "
                  reviews={recipe.review_count}
                  ratingStar={recipe.average_rating}
                />

                <div className="text-xs pt-1">
                  <p className="text-gray-400">
                    {formatDate(recipe.createdAt)}
                  </p>
                </div>
              </div>
              {admin && (
                <OptionDropDown
                  edit
                  onClickDelete={() => {
                    setShowDeleteModal(true);
                    setSelectedRecipeId(recipe.recipe_id);
                  }}
                  onClickEdit={() =>
                    navigate(`/edit-recipe/${recipe.recipe_id}`)
                  }
                />
              )}
            </div>
          </div>
        </li>
      ))}
    </>
  );
};

export default UserPost;
