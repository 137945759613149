import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import axios from "../../api/axios";
import styles from "./AddRecipe.module.css";

const CategorySelect = ({ onChange, value, onBlur, invalid, defaultValue }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("recipes/categories");
        if (res.status === 200) {
          setCategories(res.data.categories);
        }
      } catch (err) {
        setError("Failed to load categories");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const selectElement = document.getElementById("category");
    selectElement.value = "";
  }, [value]);

  useEffect(() => {
    //to empty the category after submit
    const select = document.querySelector("#category");
    select.value = value;
  }, [value]);

  return (
    <div className={`${invalid} `}>
      <select
        className={`${styles.categorySelect} category`}
        onChange={onChange}
        onBlur={onBlur}
        id="category"
        value={defaultValue || ""}
      >
        <option value="">{t("Select a category")}</option>
        {categories.map((category) => (
          <option key={category.category_id} value={category.category_id}>
            {t(category.category_name)}
          </option>
        ))}
      </select>
      {error && <p className={styles.categoryError}>{error}</p>}
    </div>
  );
};

export default CategorySelect;
