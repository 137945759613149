import React, { memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import imageCompression from "browser-image-compression";

import Button from "../../components/UI/Button";
import { Pen } from "../../assets/Icons/MenuIcon";
import { updateProfile } from "../../api/request";
import { AuthContext } from "../../context/authContext";
import Notify from "../../components/UI/Notify";
import { useTranslation } from "react-i18next";
import ImagePostLoader from "../../components/UI/ImagePostLoader";

const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Required";
  }

  if (!values.last_name) {
    errors.last_name = "Required";
  }

  if (!values.username) {
    errors.username = "Required";
  } else if (values.username.length < 4) {
    errors.username = "Must be 4 characters or greter";
  }

  return errors;
};

const EditProfile = memo(({ avatar, setAvatar, profile }) => {
  const { t } = useTranslation();
  const storedData = JSON.parse(localStorage.getItem("gebetaUser"));

  const navigate = useNavigate();

  const { token, logout, login, userId } = useContext(AuthContext);

  const [imageInvalid, setImageInvalid] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!/.(jpeg|jpg|png)$/i.test(file.name)) {
      setImageInvalid(
        "Invalid file type, only jpg, jpeg, and png are allowed."
      );
      return;
    }

    if (file.size > 11 * 1024 * 1024) {
      setImageInvalid("File size is too large, maximum size is 10 MB.");
      return;
    }

    try {
      setImgLoading(true);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true, // performs the compression on a separate thread
      };
      const compressedFile = await imageCompression(file, options);
      setFile(compressedFile);

      const compressedImage = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
      setAvatar(compressedImage);
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
      setMessage("Failed to compress image. Please try again later.");
    }
    setImageInvalid("");
    setMessage("");
  };

  const formik = useFormik({
    initialValues: {
      first_name: `${profile.first_name}` || "",
      last_name: `${profile.last_name}` || "",
      username: `${profile.username}` || "",
      // phone: `${profile.phone}` || "",
    },
    validate,
    onSubmit: async (values) => {
      setMessage("");
      setImageInvalid("");
      setLoading(true);

      try {
        if (!avatar) return;

        const res = await updateProfile(token, {
          image: file,
          first_name: values.first_name,
          last_name: values.last_name,
          username: values.username,
          // phone: values.phone,
        });

        setMessage(res.data.message);
        setLoading(false);

        logout(); // log out the user
        login(
          token,
          userId,
          values.username,
          res.data.phoneEmail,
          res.data.image,
          new Date(storedData.expiration)
        ); // log in again with updated data

        navigate(`/edit-profile/${values.username}`);
      } catch (error) {
        setImageInvalid(error.response.data.message);
        setLoading(false);
      }
    },
  });

  const firstNameInvalid =
    formik.touched.first_name && formik.errors.first_name ? "invalid" : "";
  const lastNameInvalid =
    formik.touched.last_name && formik.errors.last_name ? "invalid" : "";
  const usernameInvalid =
    formik.touched.username && formik.errors.username ? "invalid" : "";
  return (
    <>
      {message && <Notify message={t(message)} type="success" />}
      <form onSubmit={formik.handleSubmit} className="flex flex-col h-full">
        <div className="w-32 mx-auto">
          {imgLoading && (
            <ImagePostLoader className={"h-32 w-full  rounded-full"} />
          )}
          {!imgLoading && (
            <div className=" relative">
              <img
                src={avatar}
                alt="Profile"
                className="w-full h-32 object-cover rounded-full cursor-pointer bg-white"
                onClick={() => document.getElementById("fileInput").click()}
              />
              <button
                type="button"
                className="absolute bottom-5 left-50% transform -translate-x-50 text-xs text-center bg-gray-200 hover:bg-slate-300 py-1 px-2 rounded-full"
                onClick={() => document.getElementById("fileInput").click()}
              >
                <Pen
                  width="12"
                  height="12"
                  fill="black"
                  className="inline-block"
                />
                <span className="ml-2 inline-block">Edit</span>
              </button>
              <div className="mt-2">
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleAvatarChange}
                />
              </div>
            </div>
          )}
          {imageInvalid ? (
            <div className="text-xs pt-2  text-red-600">{imageInvalid}</div>
          ) : null}
        </div>
        <div className="w-64 mx-auto mt-4">
          <div className="flex flex-col">
            <div className={firstNameInvalid}>
              <input
                type="text"
                placeholder={t("first name")}
                onChange={formik.handleChange}
                value={formik.values.first_name}
                onBlur={formik.handleBlur}
                name="first_name"
                className="bg-gray-200 py-2 px-3 rounded-full w-full mt-2"
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="text-xs  text-red-600">
                  {t(formik.errors.first_name)}
                </div>
              ) : null}
            </div>
            <div className={lastNameInvalid}>
              <input
                type="text"
                placeholder={t("last name")}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                onBlur={formik.handleBlur}
                name="last_name"
                className="bg-gray-200 py-2 px-3 rounded-full w-full mt-2"
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="text-xs  text-red-600">
                  {t(formik.errors.last_name)}
                </div>
              ) : null}
            </div>
            <div className={usernameInvalid}>
              <input
                type="text"
                placeholder={t("username")}
                onChange={formik.handleChange}
                value={formik.values.username}
                onBlur={formik.handleBlur}
                name="username"
                className="bg-gray-200 py-2 px-3 rounded-full w-full mt-2"
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="text-xs  text-red-600">
                  {t(formik.errors.username)}
                </div>
              ) : null}
            </div>
          </div>
          <Button
            className=" w-full h-9 mt-4 bg-secondary hover:bg-btnHover !rounded-full"
            type="submit"
            text="Update"
            loading={loading}
          />
        </div>
      </form>
    </>
  );
});

export default EditProfile;
