import React, { useEffect, useState } from "react";

const SelectInput = ({
  label,
  options,
  input,
  id,
  onChange,
  value,
  onBlur,
  invalid,
  setSelectValues,
  defaultValue,
}) => {
  let select = 0;

  if (defaultValue === "hr") {
    select = 1;
  } else if (defaultValue === "medium") {
    select = 1;
  } else if (defaultValue === "difficult") {
    select = 2;
  }

  const [selectedValue, setSelectedValue] = useState(options?.[select]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (selectedValue !== undefined)
      setSelectValues(`${value} ${selectedValue}`);
  }, [value, selectedValue, setSelectValues]);

  let width = "w-24";
  if (input === false) {
    width = "w-full";
  }

  return (
    <div key={id} className={`${invalid} sm:mr-0 mr-3 mb-4`}>
      <label
        htmlFor="formFileSm"
        className=" text-sm form-label inline-block mb-2 text-gray-700 font-semibold"
      >
        {label}
      </label>
      <div className="flex">
        {input ? (
          <input
            type="number"
            inputMode="numeric"
            pattern="[0-9]+"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            id={id}
            className=" select-input"
          />
        ) : null}

        {options ? (
          <select
            className={` ${width} option-select`}
            value={selectedValue}
            onChange={handleSelectChange}
            id={id}
          >
            {options.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
        ) : null}
      </div>
    </div>
  );
};

export default SelectInput;
