import { useTranslation } from "react-i18next";
import { EmailIcon, LocationIcon } from "../../assets/Icons/PageIcons";

const ContactMain = () => {
  const { t } = useTranslation();
  return (
    <div className="container px-4 mx-auto mb-16">
      <section className="text-gray-800">
        <div className="flex flex-wrap max-w-3xl mx-auto">
          <div className="grow-0 mx-auto shrink-0 basis-auto w-full sm:w-1/2">
            <div className="flex flex-col">
              <div className="mb-8 grow-0 shrink-0 basis-auto w-full  px-3 lg:px-6">
                <div className="flex items-start">
                  <div className="shrink-0">
                    <div className="p-4 bg-contact rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="headset"
                        className="w-5 text-white"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="grow ml-6">
                    <p className="font-bold mb-1">{t("Phone Number")}r</p>
                    <p className="text-gray-500">+2519-62-37-33-27</p>
                  </div>
                </div>
              </div>
              <div className="mb-8 grow-0 shrink-0 basis-auto w-full  px-3 lg:px-6">
                <div className="flex items-start">
                  <div className="shrink-0">
                    <div className="p-4 bg-contact rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                      <EmailIcon fill="white" />
                    </div>
                  </div>
                  <div className="grow ml-6">
                    <p className="font-bold mb-1">{t("email")}</p>
                    <p className="text-gray-500">gebetarecipes@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="mb-8 grow-0 shrink-0 basis-auto w-full  px-3 lg:px-6">
                <div className="flex align-start">
                  <div className="shrink-0">
                    <div className="p-4 bg-contact rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                      <LocationIcon fill="white" />
                    </div>
                  </div>
                  <div className="grow ml-6">
                    <p className="font-bold mb-1">Location</p>
                    <p className="text-gray-500">Adama,Ethiopia</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactMain;
