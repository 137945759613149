import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  AddIcon,
  HomeIcon,
  SavedIcon,
  SearchIcon,
} from "../../assets/Icons/MenuIcon";
import { SideMenuContext } from "../../context/sideMenuContext";

function BottomNav() {
  const { handleSearchButtonClick } = useContext(SideMenuContext);

  return (
    // h-screen
    <div className="w-full  block md:hidden ">
      <section
        id="bottom-navigation"
        className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
      >
        <div id="tabs" className="flex justify-between">
          <NavLink
            end
            to="/"
            style={({ isActive }) =>
              isActive ? { color: "#ee5873" } : { color: "black" }
            }
            className="w-full focus:text-secondary hover:text-secondary justify-center inline-block text-center pt-2 pb-1"
          >
            <HomeIcon
              width="25"
              height="25"
              className="inline-block mb-1"
              thick="16"
            />
            <span className="tab tab-home block text-xs">Home</span>
          </NavLink>

          <button
            onClick={handleSearchButtonClick}
            className="w-full justify-center inline-block text-center pt-2 pb-1"
          >
            <SearchIcon
              width="25"
              height="25"
              className="inline-block mb-1"
              thick="16"
            />
            <span className="tab tab-home block text-xs">Search</span>
          </button>

          <NavLink
            end
            to="/add-recipe"
            style={({ isActive }) =>
              isActive ? { color: "#ee5873" } : { color: "black" }
            }
            className="w-full focus:text-secondary hover:text-secondary justify-center inline-block text-center pt-2 pb-1"
          >
            <AddIcon
              width="25"
              height="25"
              className="inline-block mb-1"
              thick="16"
            />
            <span className="tab tab-home block text-xs">Add recipe</span>
          </NavLink>

          <NavLink
            end
            to="/saved"
            style={({ isActive }) =>
              isActive ? { color: "#ee5873" } : { color: "black" }
            }
            className="w-full focus:text-secondary hover:text-secondary justify-center inline-block text-center pt-2 pb-1"
          >
            <SavedIcon
              width="25"
              height="25"
              className="inline-block mb-1"
              thick="16"
            />
            <span className="tab tab-home block text-xs">Saved</span>
          </NavLink>
        </div>
      </section>
    </div>
  );
}

export default BottomNav;
