import React from "react";
import { NavLink } from "react-router-dom";

const index = ({ props }) => {
  return (
    <footer className=" p-4 bg-white shadow flex flex-col md:flex-row items-center md:justify-between pb-14 md:p-6 ">
      <span className="text-sm text-gray-500 sm:text-center ">
        <span className="pr-1"> ©2023</span>
        <NavLink href="/" className="  pl-2 hover:underline">
          Gebeta Recipes
        </NavLink>
        <a
          href="https://github.com/MULERx"
          target="_blank"
          rel="noopener noreferrer"
          className="pl-1  text-gray-700 text-xs"
        >
          Developed by{" "}
          <span className="text-gray-600 underline hover:text-gray-500">
            Mulugeta
          </span>
        </a>
      </span>

      <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500  sm:mt-0">
        <li className="mr-4 ">
          <NavLink to="/contact-us" className=" hover:underline md:mr-6">
            Contact
          </NavLink>
        </li>
        <li className="mr-4 ">
          <NavLink to="/privacy-policy" className=" mr-4 hover:underline ">
            Privacy Policy
          </NavLink>
        </li>
      </ul>
    </footer>
  );
};

export default index;
