import React, { useEffect, useState } from "react";

import UserLayout from "../User/UserLayout";
import { useParams } from "react-router-dom";
import { getUserProfile } from "../../api/request";
import EditProfile from "./EditProfile";
import PasswordReset from "./PasswordReset";
import { EditProfileSkeleton } from "../../components/UI/Skeleton";
import { Seo } from "../../util/Seo";

const Account = () => {
  const [profile, setProfile] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line
  const [activeTab, setActiveTab] = useState("profile");

  const { username } = useParams();

  useEffect(() => {
    try {
      const userRecipe = async () => {
        const res = await getUserProfile(username);

        if (res.status === 200) {
          setProfile(res.data);
          setAvatar(`${process.env.REACT_APP_ASSET_URL}/${res.data.image}`);
        }
        setIsLoading(false);
      };
      userRecipe();
    } catch (err) {}
  }, [username]);

  return (
    <UserLayout>
      <Seo
        title={profile?.username}
        description="Manage your gebeta recipes information."
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url={window.location.href}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        {/* <div className="pb-5">
          <nav className="-mb-px flex">
            <button
              onClick={() => handleTabClick("profile")}
              className={`${
                activeTab === "profile"
                  ? "border-secondary text-secondary"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }  w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm focus:outline-none`}
            >
              {t("Edit Profile")}
            </button>
            <button
              onClick={() => handleTabClick("password")}
              className={`${
                activeTab === "password"
                  ? "border-secondary text-secondary"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              } w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm focus:outline-none`}
            >
              {t("Password Reset")}
            </button>
          </nav>
        </div> */}

        <div className="py-10">
          {activeTab === "profile" ? (
            isLoading ? (
              <EditProfileSkeleton />
            ) : (
              <EditProfile
                avatar={avatar}
                setAvatar={setAvatar}
                profile={profile}
              />
            )
          ) : (
            // Password reset form content goes here
            <PasswordReset />
          )}
        </div>
      </div>
    </UserLayout>
  );
};

export default Account;
