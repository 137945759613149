import React from "react";
import { RatingIcon } from "../assets/Icons/PageIcons";
import classes from "./util.module.css";

const SetRating = ({ onChangeRating, rating, setHover, hover }) => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={
              index <= (hover || rating) ? `${classes.on}` : `${classes.off}`
            }
            onClick={() => onChangeRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <RatingIcon className="w-10 h-10  " />
          </button>
        );
      })}
    </div>
  );
};

export default SetRating;
