import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Notify = ({ message, type, timeout = 4000, onClose = () => {} }) => {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  let bgColor = "";
  let textColor = "";

  if (type === "success") {
    bgColor = "bg-green-500";
    textColor = "text-white";
  } else if (type === "warning") {
    bgColor = "bg-yellow-500";
    textColor = "text-white";
  } else if (type === "error") {
    bgColor = "bg-red-500";
    textColor = "text-white";
  } else if (type === "info") {
    bgColor = "bg-blue-500";
    textColor = "text-white";
  }

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setShow(false);
      onClose();
    }, timeout);

    return () => clearTimeout(timeoutId);
  }, [timeout, onClose]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return (
    show && (
      <div className=" fixed z-50 top-[65px] right-2">
        <div
          className={`${bgColor} ${textColor} relative p-4 rounded-md shadow-md overflow-hidden `}
        >
          <p className="font-bold">{t(type.toUpperCase())}</p>
          <p className="capitalize">{t(message)}</p>
          <button className="absolute top-0 right-0 p-2" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-4 w-4"
            >
              <path
                fillRule="evenodd"
                d="M11.414 10l4.293-4.293a1 1 0 00-1.414-1.414L10 8.586 5.707 4.293a1 1 0 00-1.414 1.414L8.586 10l-4.293 4.293a1 1 0 001.414 1.414L10 11.414l4.293 4.293a1 1 0 001.414-1.414L11.414 10z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    )
  );
};

export default Notify;
