import { useContext, useState } from "react";
import { useFormik } from "formik";
import UserLayout from "../User/UserLayout";
import { PasswordHide, PasswordShow } from "../../assets/Icons/PageIcons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import Button from "../../components/UI/Button";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";
import { Seo } from "../../util/Seo";

const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Required";
  } else if (values.first_name.length > 15) {
    errors.first_name = "Must be 15 characters or less";
  }

  if (!values.last_name) {
    errors.last_name = "Required";
  } else if (values.last_name.length > 15) {
    errors.last_name = "Must be 15 characters or less";
  }

  if (!values.phone) {
    errors.phone = "Required";
  } else if (!/^(?:\+251|251|0)?9[0-9]{8}$/.test(values.phone)) {
    errors.phone = "Invalid phone number (valid only for Ethiopia)";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 6) {
    errors.password = "Password must be 6 characters or greter";
  }

  return errors;
};

const Signup = () => {
  const { t } = useTranslation();
  const { login } = useContext(AuthContext);
  const { state } = useLocation();

  const [passwordShown, setPasswordShown] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [verifyCodeShow, setVerifyCodeShow] = useState(false);
  const [activeTab, setActiveTab] = useState("phone");

  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();

  const handleVerifyCode = (e) => {
    setCode(e.target.value);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      setMsg("");
      setSuccess("");
      setLoading(true);
      if (!verifyCodeShow) {
        try {
          const response = await axios.post(
            "users/singup",
            {
              first_name: values.first_name,
              last_name: values.last_name,
              [activeTab === "phone" ? "phone" : "email"]:
                activeTab === "phone" ? values.phone : values.email,
              password: values.password,
            },
            { withCredentials: true }
          );
          if (response.status === 201) {
            setVerifyCodeShow(true);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setMsg(error.response.data.message);
          error.response.data.errors.forEach((err) => {
            formik.errors[err.param] = err.msg;
          });
        }
      } else {
        if (!code) {
          setMsg("PLease enter verification code");
          setLoading(false);
          return;
        }

        try {
          setLoading(true);

          const response = await axios.post(
            "users/verifyCode",
            {
              [activeTab === "phone" ? "phone" : "email"]:
                activeTab === "phone" ? values.phone : values.email,
              verificationCode: code,
            },
            { withCredentials: true }
          );
          const { token, userId, username, phoneEmail, image } = response.data;

          setLoading(false);
          login(token, userId, username, phoneEmail, image);
          navigate(state?.path || "/", { replace: true });

          setLoading(false);
        } catch (error) {
          setLoading(false);
          setMsg(error.response.data.message);
          error.response.data.errors.forEach((err) => {
            formik.errors[err.param] = err.msg;
          });
        }
      }
    },
  });

  const onResendVerification = async () => {
    setResendLoading(true);
    setSuccess("");
    setMsg("");
    try {
      const response = await axios.post(
        "users/resend_verification",
        {
          [activeTab === "phone" ? "phone" : "email"]:
            activeTab === "phone" ? formik.values.phone : formik.values.email,
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        setSuccess("Yout verification code resend successful.");
      }
      setResendLoading(false);
    } catch (error) {
      setResendLoading(false);
      setSuccess("");
      setMsg(error.response.data.message);
    }
  };

  if (activeTab === "phone") {
    delete formik.errors.email;
  } else {
    delete formik.errors.phone;
  }

  return (
    <UserLayout>
      <Seo
        title="Signup | Gebeta Recipes"
        description="Sign up for Gebeta Recipes and discover a world of delicious recipes."
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url="https://gebetarecipes.com/signup"
      />
      <form
        onSubmit={formik.handleSubmit}
        className="max-w-md mx-auto px-8 mt-8 mb-16"
      >
        <div className="mb-4 flex items-center">
          <h1 className="text-xl w-full text-secondary text-center font-bold">
            {t("Sign up")}
          </h1>
        </div>
        {!verifyCodeShow ? (
          <>
            <div className="mb-4">
              <label
                htmlFor="first_name"
                className="block text-gray-700 font-bold mb-1 text-sm"
              >
                {t("first name")}
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  formik.touched.first_name && formik.errors.first_name
                    ? "border-red-500"
                    : ""
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <p className="text-red-500 text-xs italic">
                  {t(formik.errors.first_name)}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="last_name"
                className="block text-gray-700 font-bold mb-1 text-sm"
              >
                {t("last name")}
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  formik.touched.last_name && formik.errors.last_name
                    ? "border-red-500"
                    : ""
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <p className="text-red-500 text-xs italic">
                  {t(formik.errors.last_name)}
                </p>
              )}
            </div>

            <div className="flex space-x-2 justify-center mb-2">
              <button
                type="button"
                className={`px-3 py-1 text-sm  rounded-full ${
                  activeTab === "phone"
                    ? "bg-gray-700 text-white"
                    : "text-gray-700 bg-gray-100"
                }`}
                onClick={() => handleTabClick("phone")}
              >
                {t("Phone Number")}
              </button>
              <button
                type="button"
                className={`px-3 py-1 text-sm  rounded-full ${
                  activeTab === "email"
                    ? "bg-gray-700 text-white"
                    : "text-gray-700 bg-gray-100"
                }`}
                onClick={() => handleTabClick("email")}
              >
                {t("email")}
              </button>
            </div>

            {activeTab === "phone" ? (
              <div className="mb-4">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    formik.touched.phone && formik.errors.phone
                      ? "border-red-500"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  placeholder="eg: 0911001100"
                />
                {formik.touched.phone && formik.errors.phone && (
                  <p className="text-red-500 text-xs italic">
                    {t(formik.errors.phone)}
                  </p>
                )}
              </div>
            ) : (
              <div className="mb-4">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="eg: example@gmail.com"
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-red-500 text-xs italic">
                    {t(formik.errors.email)}
                  </p>
                )}
              </div>
            )}

            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-700 font-bold mb-1 text-sm"
              >
                {t("Password")}
              </label>
              <div className="relative">
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    formik.touched.password && formik.errors.password
                      ? "border-red-500"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={togglePassword}
                >
                  {passwordShown ? (
                    <PasswordShow className=" w-4 h-4" fill="#ee5873" />
                  ) : (
                    <PasswordHide className=" w-4 h-4" fill="#ee5873" />
                  )}
                </span>
              </div>
              {formik.touched.password && formik.errors.password && (
                <p className="text-red-500 text-xs italic">
                  {t(formik.errors.password)}
                </p>
              )}
            </div>
          </>
        ) : (
          <div className="mb-4">
            <p className=" text-xs sm:text-sm mb-3 text-center">
              {t("The verification code has been sent successfully: ")}
              {activeTab === "phone"
                ? formik.values.phone
                : formik.values.email}
            </p>
            <div className=" flex items-center gap-1">
              <input
                type="text"
                id="code"
                name="code"
                className={`text-center font-bold text-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 
             
            }`}
                onChange={handleVerifyCode}
                value={code}
              />
              <Button
                className="h-9 w-16 font-thin text-sm bg-primary hover:bg-btnHover hover:!text-white !text-gray-800 py-2 px-3 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={onResendVerification}
                text="resend"
                loading={resendLoading}
              />
            </div>
          </div>
        )}

        {msg && (
          <div className="text-red-500 text-sm text-center mb-1">{t(msg)}</div>
        )}
        {success && (
          <div className="text-green-800 text-sm text-center mb-1">
            {t(success)}
          </div>
        )}

        <Button
          className="h-9 mb-2 w-full bg-secondary hover:bg-btnHover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
          text={!verifyCodeShow ? t("Sign up") : t("VERIFY")}
          loading={loading}
        />

        <div className="text-sm font-medium text-gray-500 ">
          {t("Have an account")}?
          <NavLink to="/login" className="text-secondary hover:underline ">
            {t("Log in")}
          </NavLink>
        </div>
      </form>
    </UserLayout>
  );
};

export default Signup;
