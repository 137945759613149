import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const ListCategories = (props) => {
  const { id, name, url, image_url } = props;
  const { t } = useTranslation();

  return (
    <li key={id} className="flex flex-none  flex-col items-center space-y-1">
      <NavLink
        end
        to={url}
        className={(navData) =>
          navData.isActive
            ? "bg-secondary  shadow-md p-[0.1rem] sm:p-[0.15rem] rounded-full hover:bg-secondary hover:shadow-md"
            : "bg-light  p-[0.1rem] sm:p-[0.15rem] rounded-full hover:bg-secondary hover:shadow-md"
        }
      >
        <div className="block h-13 bg-gray-50 p-[2px] sm:p-1 rounded-full relative ">
          <img
            src={`${process.env.REACT_APP_ASSET_URL}/${image_url}`}
            alt={name}
            className="sm:w-16 sm:h-16 w-14 h-14 rounded-full object-cover"
          />
        </div>
      </NavLink>
      <NavLink
        end
        to={url}
        className={(navData) =>
          navData.isActive
            ? "text-xs text-secondary font-semibold"
            : "text-xs text-slate-800 font-semibold"
        }
      >
        {t(name)}
      </NavLink>
    </li>
  );
};

export default ListCategories;
