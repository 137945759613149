import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { Footer } from "../../components";
import DirectionsList from "./DirectionsList";
import IngredientsList from "./IngredientsList";
import SelectInput from "./SelectInput";
import TagsInput from "./TagsInput";
import ImageInput from "./ImageInput";
import CategorySelect from "./CategorySelect";
import { addRecipe, updateRecipe } from "../../api/request";
import { AuthContext } from "../../context/authContext";
import Button from "../../components/UI/Button";
import Notify from "../../components/UI/Notify";
import { Seo } from "../../util/Seo";

const validate = (values) => {
  const errors = {};
  if (!values.title) errors.title = "Required";
  if (!values.category) errors.category = "Required";
  if (!values.prep_time) errors.prep_time = "Required";
  if (!values.cook_time) errors.cook_time = "Required";
  if (!values.servings) errors.servings = "Required";
  return errors;
};

export function Form({
  RecipeImage,
  title,
  image,
  category_id,
  prepTimeArray,
  cookTimeArray,
  difficulty_level,
  servings,
  description,
  ingredients,
  instructions,
  recipeId,
  tagsArray,
  page,
}) {
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();

  const [success, setSuccess] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [error, setError] = useState("");
  const [selectedImage, setSelectedImage] = useState(image ? image : null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    image ? image : RecipeImage
  );
  const [PrepTime, setPrepTime] = useState();
  const [difficultyValue, setDifficultyValue] = useState();
  const [cookTime, setCookTime] = useState();
  const [tags, setTags] = useState(tagsArray ? tagsArray : []);

  const handleImageChange = (image) => {
    setSelectedImage(image);
  };

  const handleImagePreview = (image) => {
    setImagePreviewUrl(image);
  };

  const handleTagsAdd = (newTags) => {
    setTags(newTags);
  };

  const [ingredientInput, setIngredientInput] = useState(
    ingredients
      ? [
          ...ingredients.map((ingredient) => ({
            ingredient,
            isValid: true,
            showIngRequiredMessage: false,
          })),
        ]
      : [
          {
            ingredient: "",
            isValid: true,
            showIngRequiredMessage: false,
          },
        ]
  );

  const handleIngredientInput = (newInput) => {
    setIngredientInput(newInput);
  };

  const ingredientsList = useMemo(
    () =>
      ingredientInput
        .filter((item) => item.ingredient !== "")
        .map((item) => item.ingredient),
    [ingredientInput]
  );

  const [directionsInput, setDirectionsInput] = useState(
    instructions
      ? [
          ...instructions.map(({ direction, image }) => ({
            direction,
            image,
            isValid: true,
            showRequiredMessage: false,
          })),
        ]
      : [
          {
            direction: "",
            image: "",
            isValid: true,
            showRequiredMessage: false,
          },
        ]
  );

  const handleDirectionInput = (newInput) => {
    setDirectionsInput(newInput);
  };

  const directionsList = useMemo(
    () =>
      directionsInput
        .filter((item) => item.direction !== "")
        .map(({ direction, image }) => ({ direction, image })),
    [directionsInput]
  );

  const formik = useFormik({
    initialValues: {
      title: title || "",
      category: category_id || "",
      prep_time: prepTimeArray?.[0] || "",
      cook_time: cookTimeArray?.[0] || "",
      difficulty: "",
      servings: servings || "",
      description: description || "",
    },
    validate,
    onSubmit: async (values) => {
      if (!selectedImage) {
        setError("image required, Please add image");
        return;
      }
      const listIng = [...ingredientInput];
      listIng.forEach((field) => {
        if (!field.ingredient) {
          field.isValid = false;
          field.showIngRequiredMessage = true;
        }
      });
      setIngredientInput(listIng);

      const listDsc = [...directionsInput];
      listDsc.forEach((field) => {
        if (!field.direction) {
          field.isValid = false;
          field.showRequiredMessage = true;
        }
      });
      setDirectionsInput(listDsc);

      if (
        listIng.some((field) => field.showIngRequiredMessage) ||
        listDsc.some((field) => field.showRequiredMessage)
      ) {
        setError("please complet the form");
        return;
      }

      try {
        setShowLoader(true);

        const body = {
          image: selectedImage,
          title: values.title,
          category: values.category,
          description: values.description,
          prep_time: PrepTime,
          cook_time: cookTime,
          difficulty: difficultyValue,
          servings: values.servings,
          ingredients: JSON.stringify(ingredientsList),
          directions: JSON.stringify(directionsList),
          tags,
        };

        let res;
        if (page === "add") {
          res = await addRecipe(token, body);
          if (res.status === 201) {
            setSuccess("Recipe Added Successfully");
            setShowLoader(false);

            setSelectedImage(null);
            setImagePreviewUrl(RecipeImage);
            values.title = "";
            values.category = "";
            values.description = "";
            values.servings = "";
            values.prep_time = "";
            values.cook_time = "";
            values.difficulty = "";
            setTags([]);
            setIngredientInput([
              {
                ingredient: "",
                isValid: true,
                showIngRequiredMessage: false,
              },
            ]);

            setDirectionsInput([
              {
                direction: "",
                image: "",
                isValid: true,
                showRequiredMessage: false,
              },
            ]);
          }
        } else if (page === "edit") {
          res = await updateRecipe(token, recipeId, body);
          if (res.status === 201) {
            setSuccess("Recipe Edited Successfully");
            setShowLoader(false);
          }
        }

        setError("");
        // navigate("/");
      } catch (error) {
        setError(error.response.data.message);
        setShowLoader(false);
      }
    },
  });

  const titleInvalid =
    formik.touched.title && formik.errors.title ? "invalid" : "";
  const categoryInvalid =
    formik.touched.category && formik.errors.category ? "invalid" : "";
  const prepTimeInvalid =
    formik.touched.prep_time && formik.errors.prep_time ? "invalid" : "";
  const cookTimeInvalid =
    formik.touched.cook_time && formik.errors.cook_time ? "invalid" : "";
  const difficultyInvalid =
    formik.touched.difficulty && formik.errors.difficulty ? "invalid" : "";
  const servingsInvalid =
    formik.touched.servings && formik.errors.servings ? "invalid" : "";

  return (
    <>
      {success && (
        <Notify
          message={success}
          type="success"
          onClose={() => setSuccess("")}
        />
      )}

      <Seo
        title={`${page === "add" ? "Add" : "Edit"} Recipe | Gebeta Recipes`}
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url="https://gebetarecipes.com"
      />
      <div className="main-section sm:px-6 sm:py-4 pb-10 ">
        <div className="m-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="max-w-[800px] w-full mx-auto pb-8  mb-24 px-2 sm:p-4 rounded-md bg-gray-50 sm:bg-white ">
              <div className={`invalid-img mb-6`}>
                <label
                  htmlFor="image"
                  className="inline-block text-gray-700 font-semibold"
                >
                  {t("Import recipe image")}*
                </label>
                <ImageInput
                  onImageChange={handleImageChange}
                  imagePreviewUrl={imagePreviewUrl}
                  onImagePreview={handleImagePreview}
                />
                <p
                  className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  png, jpg, or jpeg (2mb).
                </p>
              </div>

              <div className={`${titleInvalid} mt-6 mb-6`}>
                <label
                  htmlFor="title"
                  className="form-label inline-block mb-2 text-gray-700 font-semibold"
                >
                  {t("Title")}*
                </label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                  name="title"
                  id="title"
                  type="text"
                  className="add-recipe-input"
                  placeholder={t("Doro wot aserar")}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="text-xs  text-red-600">
                    {t(formik.errors.title)}
                  </div>
                ) : null}
              </div>

              <div className={`${categoryInvalid} mt-6 mb-6`}>
                <label
                  htmlFor="image"
                  className="inline-block text-gray-700 font-semibold"
                >
                  {t("Category")}*
                </label>
                <CategorySelect
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  onBlur={formik.handleBlur}
                  invalid={categoryInvalid}
                  defaultValue={formik.values.category}
                />
                {formik.touched.category && formik.errors.category ? (
                  <div className="text-xs  text-red-600">
                    {t(formik.errors.category)}
                  </div>
                ) : null}
              </div>

              <div className="grid sm:grid-cols-[200px_200px] grid-cols-[1fr_1fr] sm:gap-x-8 mb-6 justify-center">
                <SelectInput
                  label={t("Prepare Time")}
                  options={["mins", "hr"]}
                  defaultValue={prepTimeArray?.[1]}
                  input={true}
                  id="prep_time"
                  onChange={formik.handleChange}
                  setSelectValues={setPrepTime}
                  value={formik.values.prep_time}
                  onBlur={formik.handleBlur}
                  invalid={prepTimeInvalid}
                />
                <SelectInput
                  label={t("Cook Time") + "*"}
                  options={["mins", "hr"]}
                  defaultValue={cookTimeArray?.[1]}
                  input={true}
                  id="cook_time"
                  onChange={formik.handleChange}
                  setSelectValues={setCookTime}
                  value={formik.values.cook_time}
                  onBlur={formik.handleBlur}
                  invalid={cookTimeInvalid}
                />
                <SelectInput
                  label={t("Difficulty") + "*"}
                  options={["easy", "medium", "difficult"]}
                  defaultValue={difficulty_level}
                  input={false}
                  id="difficulty"
                  setSelectValues={setDifficultyValue}
                  onChange={formik.handleChange}
                  value={formik.values.difficulty}
                  onBlur={formik.handleBlur}
                  invalid={difficultyInvalid}
                />
                <SelectInput
                  label={t("Servings") + "*"}
                  input={true}
                  id="servings"
                  onChange={formik.handleChange}
                  value={formik.values.servings}
                  onBlur={formik.handleBlur}
                  invalid={servingsInvalid}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label inline-block mb-2 text-gray-700 font-semibold"
                >
                  {t("Description")}
                </label>
                <textarea
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  name="description"
                  rows="3"
                  className="add-recipe-input "
                  placeholder={t("Some description about the recipe.")}
                ></textarea>
              </div>

              <IngredientsList
                inputFields={ingredientInput}
                onIngredientInputChange={handleIngredientInput}
              />
              <DirectionsList
                inputFields={directionsInput}
                onDirectionInputChange={handleDirectionInput}
              />

              <div className="mb-12">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label inline-block mb-2 text-gray-700 font-semibold"
                >
                  {t("Tags")}
                </label>
                <TagsInput
                  className="add-recipe-input"
                  onTagsAdd={handleTagsAdd}
                  tags={tags}
                />
              </div>

              <div className="text-sm  text-red-600 text-center mb-2">
                {t(error)}
              </div>

              <div className="flex justify-center">
                <Button
                  type="submit"
                  loading={showLoader}
                  disabled={showLoader}
                  className=" w-[130px] h-9 bg-secondary hover:bg-btnHover !rounded-full"
                  text={page === "add" ? t("Add Recipe") : t("Edit Recipe")}
                />
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Form;
