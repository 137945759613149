import React from "react";
import { NavLink } from "react-router-dom";
import { Seo } from "../util/Seo";

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <Seo
        title="404 | Gebeta Recipes"
        description="Page not found in gebeta recipes."
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url="https://gebetarecipes.com/password-reset"
      />
      <div className="flex flex-col items-center">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <p className="text-xl font-medium text-gray-800">
          Oops! Page not found.
        </p>
        <NavLink
          to="/"
          className="mt-4 px-4 py-2 text-white bg-gray-800 rounded-md hover:bg-gray-900"
        >
          Go back to home
        </NavLink>
      </div>
    </div>
  );
};

export default NotFoundPage;
