import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function OptionDropDown({ onClickDelete, onClickEdit, edit }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left " ref={dropdownRef}>
      <div>
        <button
          type="button"
          onClick={toggleDropdown}
          className="text-center inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm  px-2  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-secondary"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <svg
            className=" sm:h-4 sm:w-4 h-3 w-3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M3 9a2 2 0 114 0 2 2 0 01-4 0zm7 0a2 2 0 114 0 2 2 0 01-4 0zm7 0a2 2 0 114 0 2 2 0 01-4 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0  w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            {edit && (
              <button
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-light2 w-full text-left"
                onClick={() => {
                  onClickEdit();
                  toggleDropdown();
                }}
                role="menuitem"
                tabIndex="-1"
                id="options-menu-0"
              >
                {t("edit")}
              </button>
            )}
            <button
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-light2 w-full text-left"
              onClick={() => {
                onClickDelete();
                toggleDropdown();
              }}
              role="menuitem"
              tabIndex="-1"
              id="options-menu-1"
            >
              {t("delete")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default OptionDropDown;
