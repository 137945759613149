import { NavLink } from "react-router-dom";

import UserLayout from "./User/UserLayout";
import { Trash } from "../assets/Icons/MenuIcon";
import { useContext, useEffect, useState } from "react";
import { getSavedRecipes, saveOrUnsaveRecipe } from "../api/request";
import { AuthContext } from "../context/authContext";
import { SaveContext } from "../context/saveContext";
import { UserRecipeListSkeleton } from "../components/UI/Skeleton";
import { useTranslation } from "react-i18next";
import MainLoader from "../components/UI/MainLoader";
import { Seo } from "../util/Seo";

const Liked = () => {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const { removeFavorite } = useContext(SaveContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saveRecipe, setSaveRecipe] = useState([]);

  useEffect(() => {
    const fetchSavedRecipe = async () => {
      try {
        if (token) {
          const res = await getSavedRecipes(token);

          if (res.status === 200) {
            setSaveRecipe(res.data);
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchSavedRecipe();
  }, [token]);

  const removeRecipe = async (recipeId) => {
    setIsLoading(true);
    try {
      const res = await saveOrUnsaveRecipe(token, recipeId);

      if (res.status === 204) {
        const updatedSave = saveRecipe.filter(
          (recipe) => recipe.recipe_id !== recipeId
        );

        removeFavorite(recipeId);
        setSaveRecipe(updatedSave);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  if (isLoading) {
    return <MainLoader />;
  }

  let content;

  if (saveRecipe.length === 0) {
    content = (
      <div className=" bg-slate-300">
        <h1 className=" text-center text-lg py-9 text-white font-bold">
          {t("No Saved Recipe")}
        </h1>
      </div>
    );
  } else {
    content = saveRecipe.map((recipe) => (
      <li
        key={recipe.recipe_id}
        className=" rounded-md  ml-1 sm:ml-0 mb-6 px-2 sm:px-0"
      >
        <div className="grid grid-cols-[128px_minmax(128px,_1fr)] gap-1 sm:gap-3">
          <NavLink
            to={`/recipe/${recipe.recipe_id}`}
            className=" w-[120px] h-[80px] rounded-md overflow-hidden"
          >
            <img
              src={`${process.env.REACT_APP_ASSET_URL}/${recipe.Recipe?.image}`}
              alt="liked"
              className=" h-[80px] object-cover"
            />
          </NavLink>
          <div className=" my-auto overflow-hidden flex justify-between gap-2 ">
            <div>
              <NavLink
                to={`/recipe/${recipe.recipe_id}`}
                className=" leading-3 sm:leading-none capitalize text-sm font-semibold pb-1"
              >
                {recipe?.Recipe?.title}
              </NavLink>

              <div className="text-xs pt-1">
                <NavLink
                  to={`/author/${recipe?.Recipe?.user?.username}`}
                  className="text-gray-400 hover:underline"
                >
                  by
                  <span className=" pl-1 font-semibold text-black">
                    {recipe?.Recipe?.user?.username}
                  </span>
                </NavLink>
              </div>
            </div>
            <button onClick={() => removeRecipe(recipe.recipe_id)}>
              <Trash width="20" height="20" fill="#ee5873" />
            </button>
          </div>
        </div>
      </li>
    ));
  }

  return (
    <UserLayout>
      <div className=" max-w-lg m-auto">
        <Seo
          title="Saved Recipes | Gebeta Recipes"
          description="View and manage your saved recipes on Gebeta Recipes."
          content="website"
          image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
          url="https://gebetarecipes.com/saved"
        />
        <ul className="mt-4 sm:mt-8 ">
          {isLoading ? (
            <>
              <UserRecipeListSkeleton />
              <UserRecipeListSkeleton />
              <UserRecipeListSkeleton />
            </>
          ) : (
            content
          )}
        </ul>
      </div>
    </UserLayout>
  );
};

export default Liked;
