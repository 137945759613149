import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";

function RequireAuth({ children }) {
  const { isLoggedIn } = useContext(AuthContext);
  const [hasCheckedAuth, setHasCheckedAuth] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHasCheckedAuth(true);
  }, []);

  if (!hasCheckedAuth) {
    return null;
  }

  return isLoggedIn === true ? (
    children
  ) : (
    <Navigate
      to="/login"
      replace
      state={{
        path: location.pathname,
      }}
    />
  );
}
export default RequireAuth;
