import ListCategories from "./ListCategories";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { CategorySkeleton } from "../../components/UI/Skeleton";

const Category = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get("recipes/has-categories");
        if (res.status === 200) {
          setCategories(res.data.categories);
          setLoading(false);
        }
      } catch (error) {}
    };
    fetchCategories();
  }, []);

  return (
    <section className="bg-gray-50 top-[56px] md:top-[64px] z-20 flex justify-center items-start">
      <ul className="pl-2 w-full mt-4 flex justify-start items-start space-x-4 sm:space-x-6 overflow-x-scroll categories pb-3 ">
        {loading ? (
          <>
            <CategorySkeleton />
            <CategorySkeleton />
            <CategorySkeleton />
            <CategorySkeleton />
            <CategorySkeleton />
          </>
        ) : (
          categories.map((category) => (
            <ListCategories
              key={category.category_id}
              id={category.category_id}
              name={category.category_name}
              url={category.category_name}
              image_url={category.category_image}
            />
          ))
        )}
      </ul>
    </section>
  );
};

export default Category;
