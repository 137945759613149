import React from "react";

import gebetaBanner from "../../assets/gebetaBanner.webp";

const HeadBanner = (props) => {
  return (
    <div className={`${props.addRecipeClass} header-banner`}>
      <img src={gebetaBanner} alt="amazing collection of food" />
      {props.children}
      <div className=" absolute inset-0 w-full bg-black z-0 opacity-40"></div>
    </div>
  );
};

export default HeadBanner;
