import { HomeIcon, SavedIcon, AddIcon } from "../../assets/Icons/MenuIcon";

const menu = [
  {
    to: "/",
    name: "Home",
    icon: <HomeIcon className="" width="24" height="24" thick="26" />,
  },
  {
    to: "/saved",
    name: "Saved",
    icon: <SavedIcon className="" width="24" height="24" thick="26" />,
  },
  {
    to: "/add-recipe",
    name: "Add Recipe",
    icon: <AddIcon className="" width="24" height="24" thick="26" />,
  },
];

export default menu;
