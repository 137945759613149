import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRecipeById } from "../../api/request";
import Form from "./Form";

const EditRecipe = () => {
  const { recipeId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [recipe, setRecipe] = useState([]);

  useEffect(() => {
    const fetchRecipe = async () => {
      const res = await getRecipeById(recipeId);
      if (res.status === 200) {
        setIsLoading(false);
        setRecipe(res.data);
      }
    };
    fetchRecipe();
  }, [recipeId]);

  const prepTimeArray = recipe.prep_time
    ? recipe.prep_time.trim().split(" ")
    : [];

  const cookTimeArray = recipe.cook_time
    ? recipe.cook_time.trim().split(" ")
    : [];

  const tags = recipe?.tags;
  const tagsArray = tags?.split(",");

  return (
    <>
      {!isLoading && (
        <Form
          title={recipe.title}
          image={`${process.env.REACT_APP_ASSET_URL}/${recipe.image}`}
          category_id={recipe.category_id}
          prepTimeArray={prepTimeArray}
          cookTimeArray={cookTimeArray}
          difficulty_level={recipe.difficulty_level.trim()}
          servings={recipe.servings}
          description={recipe.description}
          ingredients={JSON.parse(recipe.ingredients)}
          instructions={JSON.parse(recipe.instructions)}
          recipeId={recipe.recipe_id}
          tagsArray={tagsArray}
          page="edit"
        />
      )}
    </>
  );
};

export default EditRecipe;
