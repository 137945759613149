import ContactMain from "./ContactMain";
import ContactSocial from "./ContactSocial";
import { HeaderBanner, Footer } from "../../components";
import { Seo } from "../../util/Seo";

const Contact = () => {
  return (
    <div className="main-section ">
      <Seo
        title="Contact Us | Gebeta Recipes"
        description="Contact us with your questions, comments, or feedback about Gebeta Recipes."
        content="website"
        image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
        url="https://gebetarecipes.com/contact-us"
      />
      <div className="sm:px-6">
        <HeaderBanner>
          <ContactSocial />
        </HeaderBanner>
        <div className="mt-8">
          <ContactMain />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
