const HeaderImage = (props) => {
  return (
    <div className=" relative sm:rounded-md overflow-hidden max-w-[600px] w-full mx-auto sm:mt-6">
      <img
        src={props.img}
        className=" w-full object-cover sm:h-60 h-40"
        alt="sfsd"
      />
      {!props.add && (
        <div className=" absolute inset-0 w-full bg-black z-0 opacity-25"></div>
      )}
      <div className="">{props.children}</div>
    </div>
  );
};

export default HeaderImage;
