import React from "react";
import { useTranslation } from "react-i18next";

const Select = ({ onSelect, options, selectedOption }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full sm:my-4 my-2">
      <select
        value={selectedOption}
        onChange={onSelect}
        className="block w-sm py-1 px-2 text-xs mx-auto sm:mx-0 border border-gray-300 bg-transparent rounded-md shadow-sm focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="text-gray-700 text-sm font-medium"
          >
            {t(option.label)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
