import { useContext } from "react";
import { NavLink } from "react-router-dom";
import menu from "./MenuList";
import { SideMenuContext } from "../../context/sideMenuContext";

const SideBar = () => {
  const { sideMenuOpen } = useContext(SideMenuContext);

  return (
    <div className={`sidebar ${sideMenuOpen ? "open" : ""}  hidden md:block`}>
      <div className="sidebarinner">
        <nav className=" relative sidebarmenu text-secondary ">
          {menu.map((menu) => (
            <NavLink
              key={menu.to}
              className="sideBar-btn"
              end
              style={({ isActive }) => (isActive ? { color: "black" } : {})}
              aria-current="page"
              to={menu.to}
            >
              {menu.icon}

              <span>{menu.name}</span>
            </NavLink>
          ))}
          <div className=" absolute bottom-5 left-0 pl-4 sidebar_footer">
            <NavLink
              to="/contact-us"
              className=" text-gray-600 text-xs hover:text-gray-800 hover:underline pr-3"
            >
              contact us
            </NavLink>
            <NavLink
              to="/privacy-policy"
              className=" text-gray-600 text-xs hover:text-gray-800 hover:underline "
            >
              privacy-policy
            </NavLink>
            <p className=" text-sm capitalize font-semibold">
              © 2023 gebeta recipe
            </p>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
