import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
// eslint-disable-next-line
import { ArrowDownIcon } from "../../assets/Icons/MenuIcon";
import { AuthContext } from "../../context/authContext";
import UserButtonList from "./UserButtonList";

function UserButton() {
  const { image } = useContext(AuthContext);

  const [isOpne, setIsOpen] = useState(false);

  const rightMenuCloseHandler = () => {
    setIsOpen(!isOpne);
  };

  function useCloseOnOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }

      // Add event listener for clicks on the document
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Remove event listener when component unmounts
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  }

  const menuRef = useRef(null);

  useCloseOnOutsideClick(menuRef, () => setIsOpen(false));

  return (
    <div
      ref={menuRef}
      className=" relative flex items-center md:order-2 mr-4 ml-2"
    >
      <button
        type="button"
        className=" relative flex text-sm bg-gray-800 rounded-full md:mr-0 "
        onClick={rightMenuCloseHandler}
      >
        <img
          className="sm:w-10 sm:h-10 w-10 h-10 rounded-full object-cover"
          src={`${process.env.REACT_APP_ASSET_URL}/${image}`}
          alt="user_photo"
        />
        <div className=" absolute bottom-0 right-0 bg-light2 rounded-full w-4 h-4 flex items-center justify-center">
          <ArrowDownIcon
            width="13"
            height="13"
            thick="22"
            className="inline-block text-gray-700"
          />
        </div>
      </button>
      {isOpne && <UserButtonList onClick={rightMenuCloseHandler} />}
    </div>
  );
}

export default UserButton;
