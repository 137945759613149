import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import SetRating from "../../util/SetRating";
import { createReview } from "../../api/request";
import { AuthContext } from "../../context/authContext";
import Button from "../../components/UI/Button";
import Notify from "../../components/UI/Notify";
import { useTranslation } from "react-i18next";

const MAX_COMMENT_LENGTH = 200;

const AddRating = ({ reviewsData, setReviews, setCount }) => {
  const { t } = useTranslation();
  const { isLoggedIn, token, userId } = useContext(AuthContext);
  const { recipeId } = useParams();

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [comment, setComment] = useState("");
  const [commentLength, setCommentLength] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");

  const commentChangeHandler = (e) => {
    const value = e.target.value;
    setCommentLength(value.length);
    if (commentLength > MAX_COMMENT_LENGTH) {
      setError(
        `Your comment should be less than ${MAX_COMMENT_LENGTH} characters.`
      );
      return;
    }
    setComment(value);
    setError("");
  };

  const ratingCangeHandler = (rate) => {
    setRating(rate);
    setError("");
    setMsg("");
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!token || !isLoggedIn) {
      setShowNotify(true);
      return;
    }

    if (rating === 0) {
      setError("Please select your rateing first for this recipe");
      return;
    }
    setError("");
    setMsg("");

    try {
      setLoading(true);
      const review = {
        recipe_id: recipeId,
        comment,
        rating,
      };
      const res = await createReview(token, review);

      const newReview = {
        review_id: res.data.review.review_id,
        comment: res.data.review.comment,
        rating: res.data.review.rating,
        createdAt: res.data.review.createdAt,
        User: {
          user_id: userId,
          first_name: res.data.user.first_name,
          last_name: res.data.user.last_name,
          username: res.data.user.username,
          image: res.data.user.image,
        },
      };

      setReviews([newReview, ...reviewsData]);
      setCount((prev) => prev + 1);

      setMsg("Thank you for your review.");

      setRating(0);
      setHover(0);
      setComment("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMsg("");
      setError(error.response.data.message || "Something is wrong.");
    }
  };

  const notifyCloseHandler = () => {
    setShowNotify(false);
  };

  return (
    <>
      {msg && <Notify message={msg} type="success" />}
      {showNotify && (
        <Notify
          message={t("Plase Login Or Register First")}
          type="warning"
          onClose={notifyCloseHandler}
        />
      )}

      <div className="bg-gray-100 p-4 rounded-md mb-4">
        <form onSubmit={submitHandler}>
          <div className=" pb-1">
            <h3 className=" text-md pb-1">
              {t("Your Rating")}
              <span className=" text-xs pl-1 text-gray-400">
                ({t("Required")})
              </span>
            </h3>
            <SetRating
              onChangeRating={ratingCangeHandler}
              rating={rating}
              setHover={setHover}
              hover={hover}
            />
          </div>
          <div>
            <h3 className="text-md pb-1">
              {t("Your Review")}
              <span className=" text-xs pl-1 text-gray-400">
                ({t("optional")})
              </span>
            </h3>
            <textarea
              cols="57"
              rows="4"
              maxLength={MAX_COMMENT_LENGTH + 5}
              value={comment}
              placeholder={t("What did you think about this recipe?")}
              className=" w-full border border-gray-300 p-2"
              onChange={commentChangeHandler}
            ></textarea>
          </div>
          {error && (
            <p className="text-sm  text-red-600 text-center my-1">{t(error)}</p>
          )}
          <div className="flex justify-center pt-2">
            <Button
              type="submit"
              loading={loading}
              className=" w-[110px] h-9 bg-secondary hover:bg-btnHover !rounded-full"
              text={t("submit")}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddRating;
