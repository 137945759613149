import { useState } from "react";
import { useFormik } from "formik";
import UserLayout from "../User/UserLayout";
import { NavLink, useNavigate } from "react-router-dom";
import {
  BackArrowIcon,
  PasswordHide,
  PasswordShow,
} from "../../assets/Icons/PageIcons";
import Button from "../../components/UI/Button";
import axios from "../../api/axios";
import { useTranslation } from "react-i18next";
import Notify from "../../components/UI/Notify";
import { Seo } from "../../util/Seo";

const validate = (values) => {
  const errors = {};

  if (!values.phone) {
    errors.phone = "Required";
  } else if (!/^(?:\+251|251|0)?9[0-9]{8}$/.test(values.phone)) {
    errors.phone = "Invalid phone number (valid only for Ethiopia)";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.code) {
    errors.code = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 6) {
    errors.password = "Password must be at least 6 characters long";
  }

  return errors;
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [newPassShow, setNewPssShow] = useState(false);
  const [activeTab, setActiveTab] = useState("phone");
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      email: "",
      code: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      setLoading(true);
      setMsg("");

      if (!newPassShow) {
        try {
          const response = await axios.post(
            "users/resend_verification",
            {
              [activeTab === "phone" ? "phone" : "email"]:
                activeTab === "phone" ? values.phone : values.email,
            },
            { withCredentials: true }
          );

          if (response.status === 200) {
            setNewPssShow(true);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setMsg(error.response.data.message);
        }
      } else {
        try {
          const response = await axios.patch(
            "users/reset-password",
            {
              verificationCode: values.code,
              newPassword: values.password,
              [activeTab === "phone" ? "phone" : "email"]:
                activeTab === "phone" ? values.phone : values.email,
            },
            { withCredentials: true }
          );

          if (response.status === 200) {
            setSuccess("Password Reset Successfully");
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, 3000);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setMsg(error.response.data.message);
        }
      }

      setLoading(false);
    },
  });

  if (!newPassShow) {
    delete formik.errors.code;
    delete formik.errors.password;
  }

  if (activeTab === "phone") {
    delete formik.errors.email;
  } else {
    delete formik.errors.phone;
  }

  return (
    <UserLayout>
      <>
        <Seo
          title="Password Reset | Gebeta Recipes"
          description="Reset your Gebeta Recipes password and get back to exploring delicious recipes."
          content="website"
          image="https://node.gebetarecipes.com/uploads/gebeta/gebeta-logo.webp"
          url="https://gebetarecipes.com/password-reset"
        />

        {success && (
          <Notify
            message={t(success)}
            type="success"
            onClose={() => setSuccess("")}
          />
        )}

        <form
          onSubmit={formik.handleSubmit}
          className="max-w-md mx-auto px-8 my-8"
        >
          <div className="mb-4 flex items-center">
            {newPassShow && (
              <button
                onClick={() => !loading && setNewPssShow(false)}
                type="button"
              >
                <BackArrowIcon className="w-4 h-4" />
              </button>
            )}
            <h1 className="w-full text-xl text-secondary mb-4 text-center font-bold">
              {t("Reset password")}
            </h1>
          </div>

          {!newPassShow ? (
            <>
              <div className="flex space-x-2 justify-center mb-2">
                <button
                  type="button"
                  className={`px-3 py-1 text-sm  rounded-full ${
                    activeTab === "phone"
                      ? "bg-gray-700 text-white"
                      : "text-gray-700 bg-gray-100"
                  }`}
                  onClick={() => handleTabClick("phone")}
                >
                  {t("Phone Number")}
                </button>
                <button
                  type="button"
                  className={`px-3 py-1 text-sm  rounded-full ${
                    activeTab === "email"
                      ? "bg-gray-700 text-white"
                      : "text-gray-700 bg-gray-100"
                  }`}
                  onClick={() => handleTabClick("email")}
                >
                  {t("email")}
                </button>
              </div>

              {activeTab === "phone" ? (
                <div className="mb-4">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.phone && formik.errors.phone
                        ? "border-red-500"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="eg: 0911001100"
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <p className="text-red-500 text-xs italic">
                      {t(formik.errors.phone)}
                    </p>
                  )}
                </div>
              ) : (
                <div className="mb-4">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.email && formik.errors.email
                        ? "border-red-500"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder="eg: example@gmail.com"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="text-red-500 text-xs italic">
                      {t(formik.errors.email)}
                    </p>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="mb-4">
                <label
                  htmlFor="code"
                  className="block text-gray-700 font-bold mb-1"
                >
                  {t("Confirm code")}
                </label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  autoComplete="off"
                  className={`text-center font-bold text-lg shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    formik.touched.code && formik.errors.code
                      ? "border-red-500"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                />
                {formik.touched.code && formik.errors.code && (
                  <p className="text-red-500 text-xs italic">
                    {t(formik.errors.code)}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-bold mb-1"
                >
                  {t("New Password")}
                </label>
                <div className="relative">
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="password"
                    name="password"
                    autoComplete="off"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      formik.touched.password && formik.errors.password
                        ? "border-red-500"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <span
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={togglePassword}
                  >
                    {passwordShown ? (
                      <PasswordShow className=" w-4 h-4" fill="#ee5873" />
                    ) : (
                      <PasswordHide className=" w-4 h-4" fill="#ee5873" />
                    )}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <p className="text-red-500 text-xs italic">
                    {t(formik.errors.password)}
                  </p>
                )}
              </div>
            </>
          )}

          {msg && (
            <div className="text-red-500 text-sm text-center mb-1">
              {t(msg)}
            </div>
          )}

          <Button
            className="h-9 mb-2 uppercase w-full bg-secondary hover:bg-btnHover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            text={newPassShow ? t("Reset password") : t("get code")}
            loading={loading}
          />

          <div className="text-sm font-medium text-gray-500 ">
            {t("Remember your password")}?
            <NavLink to="/login" className="text-secondary hover:underline ">
              {t("Log in")}
            </NavLink>
          </div>
        </form>
      </>
    </UserLayout>
  );
};

export default ResetPassword;
