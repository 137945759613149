import React from "react";

import { RemoveItemIcon } from "../../assets/Icons/PageIcons";
import { useTranslation } from "react-i18next";

const DirectionsList = ({ inputFields, onDirectionInputChange }) => {
  const { t } = useTranslation();

  const addInputField = () => {
    onDirectionInputChange([
      ...inputFields,
      {
        direction: "",
        image: "",
        isValid: true,
        showRequiredMessage: false,
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    onDirectionInputChange(rows);
  };

  const handleChange = async (index, e) => {
    const { name, value } = e.target;
    const list = [...inputFields];

    list[index][name] = value;
    onDirectionInputChange(list);
  };

  const handleBlur = (index) => {
    const list = [...inputFields];
    if (list[index].direction) {
      list[index].isValid = true;
      list[index].showRequiredMessage = false;
    } else {
      list[index].isValid = false;
      list[index].showRequiredMessage = true;
    }
    onDirectionInputChange(list);
  };

  return (
    <div className="mb-6">
      <label
        htmlFor="exampleFormControlInput1"
        className="form-label inline-block mb-2 text-gray-700 font-semibold"
      >
        {t("Directions")}*
      </label>
      <div className=" relative flex">
        <div className=" w-1 h-auto bg-slate-100" />
        <ul className="pl-3 w-full">
          {inputFields.map((data, index) => (
            <li className=" pb-3 " key={index}>
              <div
                className={`${data.showRequiredMessage && "invalid"} relative `}
              >
                <textarea
                  type="text"
                  rows="4"
                  name="direction"
                  placeholder={`${t("step")} ${index + 1}`}
                  value={data.direction || ""}
                  onChange={(e) => handleChange(index, e)}
                  onBlur={() => handleBlur(index)}
                  className="add-recipe-input !mb-2"
                />
                {index ? (
                  <span
                    className=" remove-list-btn"
                    onClick={() => removeInputFields(index)}
                  >
                    <RemoveItemIcon width="22" height="22" />
                  </span>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {inputFields.length < 13 ? (
        <button
          type="button"
          onClick={() => addInputField()}
          className="add-list-btn"
        >
          {t("Add Step")}
        </button>
      ) : null}
    </div>
  );
};

export default DirectionsList;
