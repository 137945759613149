import React, { createContext, useState } from "react";

export const SideMenuContext = createContext({
  sideMenuOpen: false,
  expandedSearch: false,
  handleSearchButtonClick: () => {},
  toggleSideMenu: () => {},
});

export const SideMenuProvider = ({ children }) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [expandedSearch, setExpandedSearch] = useState(false);

  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  function handleSearchButtonClick() {
    setExpandedSearch(!expandedSearch);
  }

  return (
    <SideMenuContext.Provider
      value={{
        sideMenuOpen,
        toggleSideMenu,
        handleSearchButtonClick,
        expandedSearch,
      }}
    >
      {children}
    </SideMenuContext.Provider>
  );
};
