import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

function AdminAuth({ children }) {
  const { isLoggedIn, userId } = useContext(AuthContext);
  const [hasCheckedAuth, setHasCheckedAuth] = useState(false);

  useEffect(() => {
    setHasCheckedAuth(true);
  }, []);

  if (!hasCheckedAuth) {
    return null;
  }

  return isLoggedIn === true &&
    userId === `${process.env.REACT_APP_ADMIN_ID}` ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
}
export default AdminAuth;
