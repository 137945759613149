import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationAM from "./locales/am/translation.json";

i18n
  .use(initReactI18next) // pass react-i18next to i18next
  .init({
    lng: "en", // default language
    fallbackLng: "en", // fallback language
    resources: {
      en: {
        translation: translationEN,
      },
      am: {
        translation: translationAM,
      },
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false, // no need for escaping HTML
    },
  });
export default i18n;
