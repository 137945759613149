import React from "react";

function ImagePostLoader({ className }) {
  return (
    <div className={`flex justify-center items-center ${className} `}>
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-light"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 100-16v3a5 5 0 010 10v3z"
        />
      </svg>
    </div>
  );
}

export default ImagePostLoader;
