import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import MainLoader from "./components/UI/MainLoader";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";
import { Layout } from "./pages";
import RequireAuth from "./pages/RequireAuth";
import AdminAuth from "./pages/AdminAuth";

const Home = React.lazy(() => import("./pages/Home/index"));
const Signup = React.lazy(() => import("./pages/Auth/Signup"));
const Login = React.lazy(() => import("./pages/Auth/Login"));
const ResetPassword = React.lazy(() => import("./pages/Auth/ResetPassword"));
const Contact = React.lazy(() => import("./pages/ContactUs/index"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy.js"));
const AddRecipe = React.lazy(() => import("./pages/AddEditRecipe/AddRecipe"));
const EditRecipe = React.lazy(() =>
  import("./pages/AddEditRecipe/EditRecipe.js")
);
const RecipeDetail = React.lazy(() => import("./pages/RecipeDetail/index"));
const Saved = React.lazy(() => import("./pages/Saved"));
const Account = React.lazy(() => import("./pages/Account/Account"));
const Author = React.lazy(() => import("./pages/User/Author"));
const Dashboard = React.lazy(() => import("./pages/Admin/index"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

function App() {
  const helmetContext = {};
  return (
    <Layout>
      <HelmetProvider context={helmetContext}>
        <Suspense fallback={<MainLoader />}>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="/:category" element={<Home />} />
            </Route>

            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route
              path={`${process.env.REACT_APP_ADMIN_URL}`}
              element={
                <AdminAuth>
                  <Dashboard />
                </AdminAuth>
              }
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/password-reset" element={<ResetPassword />} />
            <Route path="/author/:username" element={<Author />} />

            <Route
              path="/edit-profile/:username"
              element={
                <RequireAuth>
                  <Account />
                </RequireAuth>
              }
            />
            <Route
              path="/recipe/:recipeId"
              element={
                <RequireAuth>
                  <RecipeDetail />
                </RequireAuth>
              }
            />
            <Route
              path="/edit-recipe/:recipeId"
              element={
                <RequireAuth>
                  <EditRecipe />
                </RequireAuth>
              }
            />
            <Route
              path="/saved"
              element={
                <RequireAuth>
                  <Saved />
                </RequireAuth>
              }
            />

            <Route
              path="/add-recipe"
              element={
                <RequireAuth>
                  <AddRecipe />
                </RequireAuth>
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </HelmetProvider>
    </Layout>
  );
}

export default App;
