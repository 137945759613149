import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { PasswordHide, PasswordShow } from "../../assets/Icons/PageIcons";
import Button from "../../components/UI/Button";
import { resetPassword } from "../../api/request";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import Notify from "../../components/UI/Notify";
import { useTranslation } from "react-i18next";

const validate = (values) => {
  const errors = {};

  if (!values.currentPassword) {
    errors.currentPassword = "Required";
  }

  if (!values.newPassword) {
    errors.newPassword = "Required";
  } else if (values.newPassword.length < 6) {
    errors.newPassword = "Password must be 6 characters or greter";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (values.confirmPassword && values.newPassword) {
    if (values.confirmPassword !== values.newPassword) {
      errors.confirmPassword = "Password not matched";
    }
  }

  return errors;
};

function PasswordReset() {
  const { t } = useTranslation();
  const { token, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const res = await resetPassword(
          {
            current_password: values.currentPassword,
            password: values.newPassword,
          },
          token
        );

        setMsg(res.data.message);
        setLoading(false);
        setTimeout(() => {
          logout();
          navigate("/");
        }, 5000);
      } catch (error) {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          formik.setFieldError("currentPassword", error.response.data.message);
        } else {
          formik.setFieldError(
            "currentPassword",
            "An error occurred, pleas try again later."
          );
        }
      }
    },
  });

  // show/hide password
  const toggleCurrPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };
  const toggleNewPassword = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const toggleConfPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const currentPasswordInvalid =
    formik.touched.currentPassword && formik.errors.currentPassword
      ? "invalid"
      : "";
  const newPasswordInvalid =
    formik.touched.newPassword && formik.errors.newPassword ? "invalid" : "";
  const confirmPasswordInvalid =
    formik.touched.confirmPassword && formik.errors.confirmPassword
      ? "invalid"
      : "";

  return (
    <>
      {msg && <Notify message={msg} type="success" />}

      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col h-full w-64 mx-auto"
      >
        <div className={`mb-5 ${currentPasswordInvalid}`}>
          <label
            htmlFor="currentPassword"
            className="block text-gray-700 text-xs sm:text-sm font-bold mb-2"
          >
            {t("Current Password")}
          </label>
          <div className={`relative ${currentPasswordInvalid}`}>
            <input
              onChange={formik.handleChange}
              value={formik.values.currentPassword}
              onBlur={formik.handleBlur}
              type={currentPasswordShown ? "text" : "password"}
              id="currentPassword"
              name="currentPassword"
              autoComplete="off"
              className="bg-gray-200 py-2 px-3 rounded-full w-full "
            />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={toggleCurrPassword}
            >
              {currentPasswordShown ? (
                <PasswordShow className=" w-4 h-4" fill="#ee5873" />
              ) : (
                <PasswordHide className=" w-4 h-4" fill="#ee5873" />
              )}
            </span>
          </div>
          {formik.touched.currentPassword && formik.errors.currentPassword ? (
            <div className="text-xs  text-red-600">
              {t(formik.errors.currentPassword)}
            </div>
          ) : null}
        </div>

        <div className={`mb-5  ${newPasswordInvalid}`}>
          <label
            htmlFor="confirm-new-password"
            className="block text-gray-700 text-xs sm:text-sm font-bold mb-2"
          >
            {t("New Password")}
          </label>
          <div className="relative">
            <input
              type={newPasswordShown ? "text" : "password"}
              id="newPassword"
              name="newPassword"
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              onBlur={formik.handleBlur}
              className="bg-gray-200 py-2 px-3 rounded-full w-full "
            />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={toggleNewPassword}
            >
              {newPasswordShown ? (
                <PasswordShow className=" w-4 h-4" fill="#ee5873" />
              ) : (
                <PasswordHide className=" w-4 h-4" fill="#ee5873" />
              )}
            </span>
          </div>
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <div className="text-xs  text-red-600">
              {t(formik.errors.newPassword)}
            </div>
          ) : null}
        </div>

        <div className={`mb-5  ${confirmPasswordInvalid}`}>
          <label
            htmlFor="confirm-new-password"
            className="block text-gray-700 text-xs sm:text-sm font-bold mb-2"
          >
            {t("Confirm New Password")}
          </label>
          <div className="relative">
            <input
              type={confirmPasswordShown ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              className="bg-gray-200 py-2 px-3 rounded-full w-full "
            />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={toggleConfPassword}
            >
              {confirmPasswordShown ? (
                <PasswordShow className=" w-4 h-4" fill="#ee5873" />
              ) : (
                <PasswordHide className=" w-4 h-4" fill="#ee5873" />
              )}
            </span>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="text-xs  text-red-600">
              {t(formik.errors.confirmPassword)}
            </div>
          ) : null}
        </div>
        <div className="mx-auto mt-4">
          <Button
            className=" w-28 h-9 bg-secondary hover:bg-btnHover !rounded-full"
            type="submit"
            text="Update"
            loading={loading}
          />
        </div>
      </form>
    </>
  );
}

export default PasswordReset;
