import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function LoginSignup() {
  const { t } = useTranslation();

  return (
    <>
      <NavLink
        to="/login"
        style={({ isActive }) =>
          isActive ? { color: "white", backgroundColor: "#ee5873" } : {}
        }
        className="btn-nav ml-2"
      >
        {t("Log in")}
      </NavLink>
      <NavLink
        to="/signup"
        style={({ isActive }) =>
          isActive ? { color: "white", backgroundColor: "#ee5873" } : {}
        }
        className="btn-nav hidden md:block border-[1px] rounded-full border-secondary ml-2"
      >
        {t("Sign up")}
      </NavLink>
    </>
  );
}

export default LoginSignup;
