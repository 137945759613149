import { NavLink } from "react-router-dom";
import classes from "./RecipeDetail.module.css";
import GetRating from "../../util/GetRating";
import { formatDate } from "../../util/formatDate";
import { useTranslation } from "react-i18next";

const Post = ({
  createdDate,
  title,
  username,
  userImage,
  totalRating,
  NumberOfReviews,
  description,
  prepTime,
  cookTime,
  servings,
  difficulty,
  ingredients,
  instructions,
}) => {
  const { t } = useTranslation();
  const ingredientsList = JSON.parse(ingredients);
  const directionsList = JSON.parse(instructions);

  let rated;
  if (totalRating === null) {
    rated = 0;
  } else {
    rated = parseInt(totalRating);
  }

  return (
    <>
      <h2 className=" text-3xl font-bold pt-4">{title}</h2>
      <div className="flex justify-between items-center pt-2">
        <div className="flex items-center">
          <div className="overflow-hidden rounded-full w-8 h-8">
            <NavLink to={`/author/${username}`}>
              <img
                src={`${process.env.REACT_APP_ASSET_URL}/${userImage}`}
                alt="user_profile_image"
                className=" h-8 object-cover"
              />
            </NavLink>
          </div>
          <div className="flex-grow pl-3 ">
            <div className="font-bold text-sm">
              <NavLink to={`/author/${username}`}>{username}</NavLink>
            </div>
            <div className="text-xs text-gray-600">
              <p>{formatDate(createdDate)}</p>
            </div>
          </div>
        </div>
        <GetRating
          className="sm:w-5 sm:h-5 w-4 h-4 "
          reviews={parseInt(NumberOfReviews)}
          ratingStar={rated}
        />
      </div>

      <p className=" pt-3 text-md">{description}</p>
      <div className="w-full h-auto bg-gray-200 overflow-hidden ">
        <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-2 justify-between pb-2 px-2">
          <div className=" text-center">
            <h3 className=" text-secondary text-sm sm:text-md font-smibold  uppercase">
              {t("Prepare Time")}
            </h3>
            <p className=" text-xs sm:text-sm">{prepTime}</p>
          </div>
          <div className=" text-center ">
            <h3 className=" text-secondary text-sm sm:text-md font-smibold  uppercase">
              {t("Cook Time")}
            </h3>
            <p className=" text-xs sm:text-sm">{cookTime}</p>
          </div>
          <div className=" text-center">
            <h3 className=" text-secondary text-sm sm:text-md font-smibold  uppercase">
              {t("Difficulty")}
            </h3>
            <p className=" text-xs sm:text-sm">{t(difficulty.trim())}</p>
          </div>
          <div className=" text-center">
            <h3 className=" text-secondary text-sm sm:text-md font-smibold  uppercase">
              {t("Servings")}
            </h3>
            <p className=" text-xs sm:text-sm">{servings}</p>
          </div>
        </div>
      </div>
      <div className={`${classes.recipe_ingredient} mt-4`}>
        <h2 className=" text-3xl font-bold pb-4">{t("Ingredients")}</h2>
        <ul>
          {ingredientsList.map((ingredient, index) => (
            <li key={index} className=" ml-3 text-smd pb-2">
              {ingredient}
            </li>
          ))}
        </ul>
      </div>
      <div className={`${classes.recipe__steps} mt-4 mb-4`}>
        <h2 className="text-3xl font-bold pb-4">{t("Directions")}</h2>
        <ol>
          {directionsList.map((dir, index) => (
            <li key={index} className=" pb-2">
              <p className=" capitalize text-md font-semibold">
                step {index + 1}
              </p>
              <p className=" text-md">{dir.direction}</p>
              {dir.image && (
                <img
                  src={dir.image}
                  alt={dir.image}
                  className=" w-auto max-h-96 h-auto pt-1"
                />
              )}
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

export default Post;
