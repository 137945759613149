import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

export const getAllRecipes = async (
  pageParam = 1,
  orderBy = "latest",
  options = {}
) => {
  const response = await api.get(
    `recipes?page=${pageParam}&orderBy=${orderBy}`,
    options
  );
  return response.data;
};

export const getAllRecipesByCategory = async (
  pageParam = 1,
  category,
  orderBy = "latest",
  options = {}
) => {
  const response = await api.get(
    `recipes/bycategory/${category}?page=${pageParam}&orderBy=${orderBy}`,
    options
  );
  return response.data;
};
