import loader from "../../assets/loader/mainLoader.gif";

const MainLoader = () => {
  return (
    <div className=" fixed inset-0 flex justify-center items-center">
      <img src={loader} alt="loader" className=" w-14" />
    </div>
  );
};

export default MainLoader;
