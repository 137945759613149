import React, { createContext, useCallback, useEffect, useState } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  token: null,
  userId: null,
  username: null,
  phone: null,
  image: null,
  login: () => {},
  logout: () => {},
});

let logoutTimer;

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState(null);
  // const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [image, setImage] = useState(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback(
    (token, userId, username, phone, image, expirationDate) => {
      setIsLoggedIn(true);
      setToken(token);
      setUserId(userId);
      setPhone(phone);
      setImage(image);
      setUsername(username);

      const tokenExpirationDate =
        expirationDate ||
        new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 15); //15 days

      setTokenExpirationDate(tokenExpirationDate);

      localStorage.setItem(
        "gebetaUser",
        JSON.stringify({
          userId,
          token,
          username,
          phone,
          image,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setToken(null);
    setUserId(null);
    setUsername(null);
    setPhone(null);
    setImage(null);
    localStorage.removeItem("gebetaUser");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("gebetaUser"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.token,
        storedData.userId,
        storedData.username,
        storedData.phone,
        storedData.image,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        token,
        userId,
        username,
        phone,
        image,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
