import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  LogOutIcon,
  MyRecipeIcon,
  UserIcon,
} from "../../assets/Icons/MenuIcon";
import { AuthContext } from "../../context/authContext";

function UserButtonList(props) {
  const { t } = useTranslation();
  const { logout, phone, username } = useContext(AuthContext);

  const logoutHandler = () => {
    logout();
    props.onClick();
  };

  return (
    <>
      <div
        className=" min-w-[150px] absolute z-50 top-6  sm:top-7 right-0  my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow "
        id="user-dropdown"
      >
        <div className="px-4 py-3">
          <span className="block text-xs text-center font-medium text-gray-500 truncate ">
            {phone}
          </span>
        </div>
        <ul className="py-1">
          <li>
            <NavLink
              to={`/author/${username}`}
              onClick={props.onClick}
              className=" flex items-center gap-3 capitalize  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
            >
              <MyRecipeIcon
                width="14"
                height="14"
                thick="22"
                className="inline-block"
              />
              <span>{t("my recipes")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={props.onClick}
              to={`/edit-profile/${username}`}
              style={({ isActive }) =>
                isActive ? { backgroundColor: "#F5F5F5" } : {}
              }
              className=" flex items-center gap-3 capitalize px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
            >
              <UserIcon
                width="14"
                height="14"
                thick="22"
                className="inline-block"
              />
              <span>{t("Account")}</span>
            </NavLink>
          </li>
          <li>
            <button
              onClick={logoutHandler}
              className="w-full flex items-center gap-3 capitalize  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
            >
              <LogOutIcon
                width="14"
                height="14"
                thick="22"
                className="inline-block"
              />
              <span>{t("Log out")}</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default UserButtonList;
