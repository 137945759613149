import { Footer } from "../../components";
import { BottomNav } from "../../components";

const UserLayout = (props) => {
  return (
    <>
      <div className="min-h-screen justify-between flex bg-gray-50 flex-col main-section sm:px-6 sm:py-4 pb-10  ">
        <div className="">{props.children}</div>
        <Footer />
      </div>
      <BottomNav />
    </>
  );
};

export default UserLayout;
